import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Stack,
  Typography,
  Tooltip,
  ListItemIcon,
} from "@mui/material";
import { useContext, useState } from "react";
import {
  FaBroom,
  FaCheckCircle,
  FaClock,
  FaCloudUploadAlt,
  FaEllipsisV,
  FaFileExcel,
  FaFileUpload,
  FaPencilAlt,
  FaPlusCircle,
  FaReply,
  FaStopCircle,
  FaUndo,
} from "react-icons/fa";
import { TbSettingsAutomation } from "react-icons/tb";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import CampaignNameForm from "../../../../Components/Forms/Reporting/CampaignNameForm/CampaignNameForm";
import CampaignForm from "../../../../Components/Forms/Simulations/CampaignForm/CampaignForm";
import FormModal from "../../../../Components/Modals/FormModal";
import { DataContext } from "../../../../Context/dataContext";
import {
  fileRequest,
  getRequest,
  postRequest,
} from "../../../../Helpers/httpRequests";
import { useAppSelector } from "../../../../Redux/app/hooks";
import UploadLogs from "../../../../Components/Reporting/UploadLogs/UploadLogs";
import ScoringExcelModal from "../../../Simulations/Projects/ProjectDetails/ScoringExcelModal";
import DownloadTimeline from "../DownloadTimeline";
import CampaignContext from "../provider/CampaignContext";
import { reRunStyle, timelineStyle } from "../utils";

const CampaignHeader = () => {
  const {
    campaign,
    reportName,
    reportTool,
    timeline,
    executedUUID,
    modifiedThreshold,
    setReload,
    setModifiedThreshold,
    setReportName,
    setRefresh,
    getFindings,
  } = useContext(CampaignContext);
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);

  const [openTimelineModal, setTimelineModal] = useState(false);
  const [openRenameModal, setOpenRename] = useState(false);
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [resetScoreModal, setResetScoreModal] = useState(false);
  const [openRerunSim, setRerunSim] = useState(false);
  const [uploadLogsOpen, setUploadLogsOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const genScoringExcel = async (uuid, name) => {
    const LoadingToast = toast.loading("Downloading Excel File");
    const filename = `${name.replace(/\s+/g, "_")}.xlsx`;
    try {
      const res = await fileRequest(
        `/react/api/${current_customer.uuid}/campaign/scoring_excel/${uuid}`,
        accessToken,
        filename
      );

      if (res.status === 200) {
        toast.dismiss(LoadingToast);
        toast.success("Scoring Excel Downloaded Successfully");
      } else {
        toast.dismiss(LoadingToast);
        toast.error("Error downloading scoring document");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.dismiss(LoadingToast);
      toast.error("An error occurred while downloading the document");
    }
  };

  const resetScore = async () => {
    const data = { modifiedThreshold: modifiedThreshold };
    const LoadingToast = toast.loading("Attempting to reset Score");
    setResetScoreModal(false);
    const res = await postRequest(
      `/react/api/${current_customer.uuid}/report/resetThreshold`,
      accessToken,
      data,
      true
    );
    if (res.status === 200) {
      setModifiedThreshold([]);
      setRefresh(false);
      setReload((current) => !current);
      toast.dismiss(LoadingToast);
      toast.success("Score Reset Successfully");
    } else {
      toast.dismiss(LoadingToast);
      toast.error("Failed to reset score");
    }
  };

  const uploadToPlextrac = async () => {
    const LoadingToast = toast.loading("Attempting to upload to plextrac");
    const res = await getRequest(
      `/react/api/${current_customer.uuid}/plextrac/${executedUUID}/uploadCampaign`,
      accessToken
    );
    if (res.status === 200) {
      toast.dismiss(LoadingToast);
      toast.success("Uploaded to Plextrac successfully");
    } else {
      toast.dismiss(LoadingToast);
      toast.error("Failed to Upload to Plextrac");
    }
  };

  const forceComplete = async () => {
    await postRequest(
      `/api/endpoints/force-complete/${campaign.uuid}/${campaign.endpoint.uuid}`,
      accessToken
    );
  };

  const scoreCampaign = () => async () => {
    const LoadingToast = toast.loading("Attempting to score campaign");
    const res = await getRequest(
      `/react/api/${current_customer.uuid}/beta/autoscoring/${executedUUID}`,
      accessToken
    );
    if (res.status === 200) {
      toast.dismiss(LoadingToast);
      toast.success("Campaign scored successfully");
      setRefresh(false);
      setReload((current) => !current);
    } else {
      toast.dismiss(LoadingToast);
      toast.error("Failed to score campaign");
    }
  };

  const runCleanupCampaign = () => async () => {
    const LoadingToast = toast.loading("Attempting to cleanup campaign");
    const res = await postRequest(
      `/react/api/${current_customer.uuid}/report/campaign/${executedUUID}/clean_up`,
      accessToken
    );
    if (res.status === 200) {
      toast.dismiss(LoadingToast);
      toast.success("Campaign clean up created successfully");
      setRefresh(false);
      setReload((current) => !current);
    } else {
      toast.dismiss(LoadingToast);
      toast.error("Failed to clean up Campaign");
    }
  };

  const downloadReport = async () => {
    const LoadingToast = toast.loading("Attempting to download report");
    const res = await fileRequest(
      `/react/api/${current_customer.uuid}/report/campaign/${executedUUID}/report`,
      accessToken,
      `${reportName}.docx`
    );
    if (res.status !== 200) {
      const blob = new Blob([res.data]);
      const data = await blob.text();
      const obj = JSON.parse(data);
      if (obj) toast.error(obj.msg);
      else toast.error("System error");
    } else {
      toast.dismiss(LoadingToast);
      toast.success("Report downloaded successfully");
    }
  };

  if (!campaign) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography component={"span"} fontWeight={"bold"} variant="h4">
          Findings in
          <CircularProgress sx={{ marginLeft: 2 }} />{" "}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Typography component={"span"} fontWeight={"bold"} variant="h4">
        Findings in {reportName}
      </Typography>
      <Tooltip title="Options">
        <IconButton onClick={handleClick}>
          <FaEllipsisV size={30} />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {modifiedThreshold.length > 0 && (
          <MenuItem
            onClick={() => {
              handleClose();
              setResetScoreModal(true);
            }}
          >
            <ListItemIcon>
              <FaUndo />
            </ListItemIcon>
            Revert Changes
          </MenuItem>
        )}
        {campaign.payload.archived === false && (
          <MenuItem
            onClick={() => {
              handleClose();
              setRerunSim(true);
            }}
          >
            <ListItemIcon>
              <FaReply />
            </ListItemIcon>
            ReRun Simulation
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleClose();
            setTimelineModal(true);
          }}
        >
          <ListItemIcon>
            <FaClock />
          </ListItemIcon>
          Timeline
        </MenuItem>
        {campaign.payload.cmd_output === "cs" && (
          <Link
            to={`/${current_customer.uuid}/reporting/campaign/${executedUUID}/uploadBeacon`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <FaPlusCircle />
              </ListItemIcon>
              Upload Cobalt Strike Log{" "}
            </MenuItem>
          </Link>
        )}
        <MenuItem
          onClick={() => {
            handleClose();
            setOpenRename(true);
          }}
        >
          <ListItemIcon>
            <FaPencilAlt />
          </ListItemIcon>
          Rename Campaign
        </MenuItem>
        {campaign.status !== "completed" && (
          <MenuItem
            onClick={() => {
              handleClose();
              forceComplete();
            }}
          >
            <ListItemIcon>
              <FaStopCircle />
            </ListItemIcon>
            Force Complete
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleClose();
            genScoringExcel(campaign.uuid, campaign.name);
          }}
        >
          <ListItemIcon>
            <FaFileExcel />
          </ListItemIcon>
          Download Scoring Excel
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setOpenExcelModal(true);
          }}
        >
          <ListItemIcon>
            <FaFileUpload />
          </ListItemIcon>
          Upload Scoring Excel
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setUploadLogsOpen(true);
          }}
        >
          <ListItemIcon>
            <FaFileUpload />
          </ListItemIcon>
          <Typography sx={{ marginRight: 1 }}>
            Upload Security Tool Logs
          </Typography>
        </MenuItem>
        {current_customer.features.autoscoring === true && (
          <MenuItem
            onClick={() => {
              handleClose();
              scoreCampaign()();
            }}
          >
            <ListItemIcon>
              <TbSettingsAutomation />
            </ListItemIcon>
            Auto Scoring
          </MenuItem>
        )}
        {current_customer.license_type === "consumption_smv" && (
          <MenuItem onClick={downloadReport}>
            <ListItemIcon>
              <FaCheckCircle />
            </ListItemIcon>
            Download Campaign Report{" "}
          </MenuItem>
        )}
        {reportTool &&
          reportTool.uuid &&
          campaign.payload.cmd_output !== "cs" && (
            <MenuItem onClick={uploadToPlextrac}>
              <ListItemIcon>
                <FaCloudUploadAlt />
              </ListItemIcon>
              Upload to Plextrac
            </MenuItem>
          )}
        <MenuItem onClick={runCleanupCampaign()}>
          <ListItemIcon>
            <FaBroom />
          </ListItemIcon>
          Cleanup Campaign
        </MenuItem>
      </Menu>
      {/* Scoring Excel Modal */}
      <Modal
        open={openExcelModal}
        onClose={() => setOpenExcelModal(false)}
        sx={{
          width: "30%",
          height: "30%",
          position: "absolute",
          top: "10%",
          left: "40%",
        }}
      >
        <ScoringExcelModal
          uuid={executedUUID}
          setOpenExcelModal={setOpenExcelModal}
          campaign={true}
          setRefresh={setRefresh}
        />
      </Modal>

      {/* Timeline Modal */}
      <Modal
        open={openTimelineModal}
        onClose={() => setTimelineModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={timelineStyle}>
          <DownloadTimeline timeline={timeline} reportName={reportName} />
        </Box>
      </Modal>

      {/* ReName Campaign Modal */}
      <Modal
        open={openRenameModal}
        onClose={() => setOpenRename(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <CampaignNameForm
            setOpen={setOpenRename}
            onChange={setReportName}
            campaign={campaign}
          />
        </Box>
      </Modal>
      {/* Reset Score */}
      <FormModal open={resetScoreModal} setOpen={setResetScoreModal}>
        <Box component="form">
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            Are you sure you want to revert the changes you've made ?
          </Typography>
          <Stack direction="column" spacing={2}>
            <Button onClick={() => resetScore()} variant="contained">
              Yes
            </Button>
          </Stack>
        </Box>
      </FormModal>
      {/* ReRun Sim Modal */}
      <Modal
        open={openRerunSim}
        onClose={() => setRerunSim(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={reRunStyle}>
          <CampaignForm
            simulation={campaign.payload.simulation}
            setModalOpen={setRerunSim}
            customer={current_customer}
          />
        </Box>
      </Modal>

      <UploadLogs
        setOpen={(b) => {
          setUploadLogsOpen(b);
          getFindings();
        }}
        open={uploadLogsOpen}
        campaignUUIDs={[executedUUID]}
      />
    </Box>
  );
};

export default CampaignHeader;
