import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useContext, useEffect, useState } from "react";
import { FaClone, FaPlus, FaRocket, FaTrashAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import TextButton from "../../../Components/Buttons/TextButton";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles";
import CustomerAccessesFiltered from "../../../Components/Dropdowns/CustomerAccesses/CustomerAccessesFiltered";
import FormModal from "../../../Components/Modals/FormModal";
import { DataContext } from "../../../Context/dataContext";
import { getRequest } from "../../../Helpers/httpRequests";
import { COLORS } from "../../../Styles/colors";
import { deleteSim, publishSim, duplicateSim } from "./functions";
import moment from "moment";
import { useHttpRequest } from "../../../Hooks";
import { useAppSelector } from "../../../Redux/app/hooks";

const AdminPayloadBuilder = () => {
  // ----- STATES && CONTEXT -----
  const navigate = useNavigate();
  const { accessToken } = useContext(DataContext);
  const user = useAppSelector((state) => state.user);
  const current_customer = useAppSelector(
    (state) => state.customer
  );
  const [loading, setLoading] = useState(true);
  const [loadingPublished, setLoadingPublished] = useState(true);
  const [reload, setReload] = useState(false);
  const [sims, setSims] = useState([]);
  const [publishedSims, setPublishedSims] = useState([]);
  const [categories, setCategories] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [publishModal, setPublishModal] = useState(false);
  const [selectedSim, setSelectedSim] = useState([]);
  const [data, setData] = useState({
    category: "",
    platform: "windows",
    customer_access: [],
    default: false,
    alert_validation: false,
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteUUID, setDeleteUUID] = useState("");
  const [deleteName, setDeleteName] = useState("");

  const columns = [
    {
      field: "name",
      headerName: "name",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <Link
            to={`/${current_customer.uuid}/beta/buildNewPayload/${params.row.id}`}
          >
            {params.row.name}
          </Link>
        );
      },
    },
    {
      field: "actions_count",
      headerName: "Number of Actions",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "created",
      headerName: "Created",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return <div>{moment(params.row.created).format("MMM DD, YYYY")}</div>;
      },
    },
    {
      field: "last_modified",
      headerName: "Last Modified By",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.last_modified_by[0] ? (
              <>
                {params.row.last_modified_by[0].contributor.first +
                  " " +
                  params.row.last_modified_by[0].contributor.last}{" "}
              </>
            ) : (
              <>None</>
            )}
          </>
        );
      },
    },
    {
      field: "last_modified_date",
      headerName: "Last Modified Date",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.last_modified_by[0] ? (
              <>
                {moment(
                  params.row.last_modified_by[0].modification_date
                ).format("MMM DD, YYYY")}
              </>
            ) : (
              <>None</>
            )}
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "actions",
      maxWidth: 250,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Stack direction="row" spacing={7}>
              <IconButton
                onClick={() => {
                  setPublishModal(true);
                  setSelectedSim(params.row);
                }}
                title="Publish Sim"
              >
                <FaRocket />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  duplicateSim(params.row.id, accessToken, current_customer,data, setReload);
                }}
                title="Duplicate Sim"
              >
                <FaClone />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteName(params.row.name);
                  setDeleteModal(true);
                  setDeleteUUID(params.row.id);
                }}
                title="Delete Sim"
              >
                <FaTrashAlt color={COLORS.error.main} />
              </IconButton>
            </Stack>
          </>
        );
      },
    },
  ];

  const simSelected = (sim_uuid) => {
    navigate(`/${current_customer.uuid}/beta/buildNewPayload/${sim_uuid}`);
  };

  const { response } = useHttpRequest({
    method: "GET",
    path: "/api/v2/simulations",
    params: {
      depth: 1,
    },
  });

  useEffect(() => {
    if (response) {
      setPublishedSims(response);
      setLoadingPublished(false);
    }
  }, [response, setPublishedSims]);

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const populateGrid = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/payload_builder`,
        accessToken
      );
      if (res.status === 200) {
        populateDataGrid(res.data.sims);
        setLoading(false);
        setCategories(res.data.categories);
        setPlatforms(res.data.platforms);
      }
    };
    const populateDataGrid = (sims) => {
      const rows = sims.map((sim) => {
        return {
          id: sim.uuid,
          name: sim.name,
          actions_count: sim.actions_count,
          created: sim.created_date,
          last_modified_by: sim.contributors,
          actions: [],
        };
      });
      setSims(rows);
    };
    populateGrid();
  }, [accessToken, reload, current_customer]);

  if (loading === true) {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <Stack spacing={3}>
        {/* Breadcrumbs */}
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.primary">Beta</Typography>
          <Typography color="text.primary">Simulation Builder</Typography>
        </Breadcrumbs>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          {/* Page Title */}
          <Typography variant="h3">Simulation Builder</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Typography component={"span"} className="text-links">
              <Link to={`/${current_customer.uuid}/beta/buildnewpayload`}>
                <TextButton
                  onClick={() => {}}
                  tooltip="Build New Simulation"
                  icon={FaPlus}
                />
              </Link>
            </Typography>
          </Box>
        </Box>
        <Typography variant="h5">Draft Simulations</Typography>
        <DataGridWithStyles
          name="admin-payload-builder"
          autoHeight
          paper
          rows={sims}
          columns={columns}
        />
        {loadingPublished ? (
          <Typography>
            Loading Published Sims....
            <CircularProgress />{" "}
          </Typography>
        ) : (
          <>
            {publishedSims && publishedSims.length > 0 && (
              <>
                <Typography variant="h5">
                  Published Simulations (Select to use as template)
                </Typography>
                <FormControl sx={{ width: "50%" }}>
                  <InputLabel id="select-label">
                    Select a Simulation from dropdown
                  </InputLabel>
                  {/* TODO: Change to a Menu of listitems */}
                  <Select
                    labelId="select-label"
                    onChange={(event) => simSelected(event.target.value)}
                    defaultValue={""}
                  >
                    {publishedSims.map((sim) => (
                      <MenuItem key={sim.uuid} value={sim.uuid}>
                        {sim.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
          </>
        )}

        {/* Publish */}
        <FormModal
          open={publishModal}
          setOpen={setPublishModal}
          sx={{ height: "45%" }}
        >
          <Stack component="form">
            <Typography sx={{ marginBottom: 2 }} variant="h6">
              Publish Sim {selectedSim.name}
            </Typography>
            <FormGroup>
              {user.superuser && (
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={data.default}
                      onChange={(e) =>
                        setData((prev) => ({
                          ...prev,
                          default: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Default"
                />
              )}
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={data.alert_validation}
                    onChange={(e) =>
                      setData((prev) => ({
                        ...prev,
                        alert_validation: e.target.checked,
                      }))
                    }
                  />
                }
                label="Alert Validation"
              />
            </FormGroup>
            {!data.default && (
              <CustomerAccessesFiltered formData={data} setFormData={setData} />
            )}

            {!data.alert_validation && (
              <FormControl sx={{ marginTop: 2, marginBottom: 1 }}>
                <InputLabel>Category</InputLabel>
                <Select
                  value={data.category}
                  label="Category"
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      category: e.target.value,
                    }))
                  }
                >
                  {categories &&
                    categories.length > 0 &&
                    categories.map((c) => (
                      <MenuItem key={`cat-${c}`} value={c}>
                        {c}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}

            {platforms && platforms.length > 0 && (
              <FormControl sx={{ marginBottom: 2, marginTop: 1 }}>
                <InputLabel>Platform</InputLabel>
                <Select
                  value={data.platform || ""}
                  label="Platform"
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      platform: e.target.value,
                    }))
                  }
                >
                  {platforms.map((p, index) => (
                    <MenuItem key={index} value={p[0]}>
                      {p[1]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <Button
              disabled={
                (!data.alert_validation && data.category === "") ||
                data.platform === "" ||
                (!data.default && data.customer_access.length === 0)
              }
              onClick={() =>
                publishSim(
                  selectedSim,
                  accessToken,
                  data,
                  setSims,
                  setPublishModal,
                  setData,
                  navigate,
                  current_customer
                )
              }
              variant="contained"
            >
              Submit
            </Button>
          </Stack>
        </FormModal>
        <FormModal open={deleteModal} setOpen={setDeleteModal}>
          <Stack spacing={2}>
            <Typography
              variant="h6"
              style={{ textAlign: "center", marginBottom: 4 }}
            >
              Are you sure you want to remove Draft Simulation {deleteName} ?
            </Typography>
            <Button
              onClick={() =>
                deleteSim(deleteUUID, accessToken, current_customer, setSims, setDeleteModal)
              }
              variant="contained"
            >
              Yes
            </Button>
          </Stack>
        </FormModal>
      </Stack>
    );
  }
};

export default AdminPayloadBuilder;
