import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Box,
  Breadcrumbs,
  Card,
  CardActions,
  CardHeader,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FaPlus, FaTrashAlt, FaUndo } from "react-icons/fa";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import TextButton from "../../../Components/Buttons/TextButton";
import SecurityToolAddForm from "../../../Components/Forms/Config/SecurityToolAddForm/SecurityToolAddForm";
import { DataContext } from "../../../Context/dataContext";
import { getRequest, postRequest } from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";
import SecurityToolCard from "./SecurityToolCard";
import UsageModal from "./UsageModal"; // Import UsageModal
import { COLORS } from "../../../Styles/colors";

const Config_Security_Tools = () => {
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);
  const [security_tools, setSecurityTools] = useState([]);
  const [predefinedChoices, setPredefinedChoices] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("All");
  const [selectedAvailVendor, setSelectedAvailVendor] = useState("All");
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [inActiveTools, setInactiveTools] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false); // For delete modal
  const [toolToDelete, setToolToDelete] = useState(null); // For keeping track of the tool to delete
  const [modalText, setModalText] = useState(""); // For modal text
  const [instance, setInstance] = useState(""); // For modal instance (archive, restore, delete)

  useEffect(() => {
    const getSecurityTools = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/config/securitytools`,
        accessToken
      );
      if (res.status === 200) {
        setPredefinedChoices(res.data.predefined_choices);
        setSecurityTools(res.data.security_tools);
        setInactiveTools(res.data.inactive_tools);
      }
    };

    getSecurityTools();
  }, [current_customer, accessToken, reload]);

  const handleVendorChange = (event, newValue) => {
    setSelectedVendor(newValue);
  };

  const handleVendorAvailChange = (event, newValue) => {
    setSelectedAvailVendor(newValue);
  };

  const filteredTools =
    selectedVendor === "All"
      ? security_tools
      : security_tools.filter((tool) => tool.vendor === selectedVendor);

  const filteredPredefinedChoices =
    selectedAvailVendor === "All"
      ? predefinedChoices
      : predefinedChoices.filter((tool) => tool.vendor === selectedAvailVendor);

  const uniqueVendors = [...new Set(security_tools.map((tool) => tool.vendor))];

  const handleOpenAddModal = () => {
    setAddModalOpen(true);
  };

  const handleCloseAddModal = () => {
    setAddModalOpen(false);
  };

  const addDefinedTool = async (tool) => {
    const logDestinationMap = {
      SIEM: "siem",
      "System Console": "console",
      "Other Log Destination": "other",
      "No Logs forwarded or collected": "none",
    };

    let formData = {
      name: tool.name,
      product: tool.name,
      vendor: tool.vendor,
      log_destination: logDestinationMap[tool.logDestination],
      access_url: "",
      api_key: "",
    };

    const res = await toast.promise(
      postRequest(
        `/react/api/${current_customer.uuid}/config/securitytools/add`,
        accessToken,
        formData
      ),
      {
        pending: `Adding Security Tool ${formData.name}`,
        success: `${formData.name} Security Added`,
        error: "Something went wrong!",
      }
    );

    if (res.status === 200) {
      setReload((current) => !current);
    } else {
      alert("Something went wrong, Unable to add Tool");
    }
  };

  // Open the delete modal
  const openDeleteModal = (tool, instanceType, message) => {
    setToolToDelete(tool);
    setModalText(message);
    setInstance(instanceType);
    setDeleteModalOpen(true);
  };

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">Home</Link>
        <Typography color="text.primary">Security Tools</Typography>
      </Breadcrumbs>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography component={"span"} fontWeight={"bold"} variant="h4">
          Security Tools
        </Typography>
        <TextButton
          onClick={handleOpenAddModal}
          tooltip="Create New Tool"
          icon={FaPlus}
        />
      </Box>

      {security_tools.length === 0 ? (
        <Alert severity="info">No Tools Selected</Alert>
      ) : (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h5">
              Selected Security Tools ({security_tools.length})
            </Typography>
          </AccordionSummary>

          <Tabs
            value={selectedVendor}
            onChange={handleVendorChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab value="All" label="All" />
            {uniqueVendors.map((vendor) => (
              <Tab key={vendor} value={vendor} label={vendor} />
            ))}
          </Tabs>

          <AccordionDetails>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={3} columns={18}>
                {filteredTools.map((tool, index) => (
                  <SecurityToolCard
                    key={index}
                    tool={tool}
                    setReload={setReload}
                    hide={false}
                  />
                ))}
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}

      {/* Inactive Tools Section */}
      {inActiveTools.length > 0 && (
        <Paper elevation={3} variant="outlined" sx={{ mt: 3 }}>
          <Box sx={{ p: 2 }}>
            <Typography variant="h5">Inactive Tools</Typography>
            <Grid container spacing={3} columns={18}>
              {inActiveTools.map((tool, index) => (
                <Grid
                  key={index}
                  item
                  sx={{ display: "flex" }}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={8}
                  xl={6}
                >
                  <Card
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <CardHeader
                      sx={{
                        background:
                          "linear-gradient(to right, #f9f9f9, #efefef)",
                      }}
                      avatar={
                        <Avatar
                          src={
                            vendorImages[tool.vendor] || vendorImages.default
                          }
                          sx={{ backgroundColor: "transparent" }}
                          variant="rounded"
                        />
                      }
                      title={<Typography variant="h6">{tool.name}</Typography>}
                      subheader={`${tool.vendor} - ${tool.log_destination}`}
                    />
                    <CardActions sx={{ marginTop: "auto" }}>
                      <Stack direction="row" sx={{ width: "100%" }}>
                        <Tooltip title="Restore Security Tool">
                          <IconButton
                            onClick={() =>
                              openDeleteModal(
                                tool,
                                "restore",
                                `Are you sure you want to restore ${tool.name}?`
                              )
                            }
                          >
                            <FaUndo />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Security Tool">
                          <IconButton
                            onClick={() =>
                              openDeleteModal(
                                tool,
                                "delete",
                                `Are you sure you want to delete ${tool.name}?`
                              )
                            }
                          >
                            <FaTrashAlt color={COLORS.error.main} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Paper>
      )}

      {/* Predefined Tools Section */}
      <Paper elevation={3} variant="outlined" sx={{ mt: 3 }}>
        <Box sx={{ p: 2 }}>
          <Typography variant="h5">
            Available Tools ({predefinedChoices.length})
          </Typography>
          <Tabs
            value={selectedAvailVendor}
            onChange={handleVendorAvailChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab value="All" label="All" />
            {[...new Set(predefinedChoices.map((tool) => tool.vendor))].map(
              (vendor) => (
                <Tab key={vendor} value={vendor} label={vendor} />
              )
            )}
          </Tabs>

          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3} columns={18}>
              {filteredPredefinedChoices.map((choice, index) => (
                <Grid
                  key={index}
                  item
                  sx={{ display: "flex" }}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={8}
                  xl={6}
                >
                  <Card
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <CardHeader
                      sx={{
                        background:
                          "linear-gradient(to right, #e0eafc, #cfdef3)",
                      }}
                      avatar={
                        <Avatar
                          src={
                            vendorImages[choice.vendor] || vendorImages.default
                          }
                          sx={{ backgroundColor: "transparent" }}
                          variant="rounded"
                        />
                      }
                      title={
                        <Typography variant="h6">{choice.name}</Typography>
                      }
                      subheader={`${choice.vendor} - ${choice.logDestination}`}
                    />
                    <CardActions sx={{ marginTop: "auto" }}>
                      <Stack direction="row" sx={{ width: "100%" }}>
                        <Tooltip title="Add Security Tool">
                          <IconButton onClick={() => addDefinedTool(choice)}>
                            <FaPlus />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Paper>

      {/* Add New Tool Modal */}
      <Modal
        open={addModalOpen}
        onClose={handleCloseAddModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <SecurityToolAddForm
            closeModal={handleCloseAddModal}
            setReload={setReload}
            products={predefinedChoices}
          />
        </Box>
      </Modal>

      {/* Usage Modal for restoring/deleting inactive tools */}
      <UsageModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        tool={toolToDelete}
        setTool={setToolToDelete}
        setReload={setReload}
        text={modalText}
        instance={instance}
      />
    </>
  );
};

const modalStyle = {
  position: "absolute",
  bgcolor: "background.paper",
  top: "40%",
  left: "45%",
  width: "70%",
  height: "55%",
  maxWidth: 900,
  transform: "translate(-25%, -50%)",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const vendorImages = {
  Blackberry: require("../../../Images/security_tools_icons/Blackberry.png"),
  "Carbon Black": require("../../../Images/security_tools_icons/carbon_black.jpg"),
  CheckPoint: require("../../../Images/security_tools_icons/checkpoint.png"),
  Cisco: require("../../../Images/security_tools_icons/cisco.png"),
  Crowdstrike: require("../../../Images/security_tools_icons/crowdstrike.png"),
  Elastic: require("../../../Images/security_tools_icons/elastic.png"),
  McAfee: require("../../../Images/security_tools_icons/mcafee.png"),
  Microsoft: require("../../../Images/security_tools_icons/microsoft.jpg"),
  "Palo Alto Networks": require("../../../Images/security_tools_icons/palo_alto.png"),
  SentinelOne: require("../../../Images/security_tools_icons/sentinel_one.png"),
  Tanium: require("../../../Images/security_tools_icons/tanium.png"),
  "Security Onion": require("../../../Images/security_tools_icons/security_onion.png"),
  Sophos: require("../../../Images/security_tools_icons/sophos.png"),
  Symantec: require("../../../Images/security_tools_icons/symantec.png"),
  Varonis: require("../../../Images/security_tools_icons/varonis.png"),
  WebSense: require("../../../Images/security_tools_icons/websense.png"),
  ServiceNow: require("../../../Images/security_tools_icons/service_now.png"),
  Fortinet: require("../../../Images/security_tools_icons/fortinet.png"),
  Rapid7: require("../../../Images/security_tools_icons/rapid7.png"),
  VMWare: require("../../../Images/security_tools_icons/vmware.png"),
  DeepSeas: require("../../../Images/security_tools_icons/deepseas.jpg"),
  Datto: require("../../../Images/security_tools_icons/datto.jpg"),
  defender: require("../../../Images/security_tools_icons/Windows-defender.png"),
  sentinel: require("../../../Images/security_tools_icons/sentinel.png"),
  default: require("../../../Images/security_tools_icons/default.png"),
};

export default Config_Security_Tools;
