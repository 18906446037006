import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Collapse,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";

import CircularProgress from "@mui/material/CircularProgress";
import Tab from "@mui/material/Tab";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import FormModal from "../../../Components/Modals/FormModal";
import { DataContext } from "../../../Context/dataContext";
import {
  fileRequest,
  getRequest,
  postRequest,
} from "../../../Helpers/httpRequests";
import "./spinner.css";

import {
  FaChartArea,
  FaCheckCircle,
  FaChessBoard,
  FaDownload,
  FaHourglass,
  FaSpinner,
  FaTimesCircle,
  FaTrashAlt,
} from "react-icons/fa";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import { useAppSelector } from "../../../Redux/app/hooks";
import { COLORS } from "../../../Styles/colors";
import { getExecutionMethod } from "../../Simulations/Campaigns/CampaignsList/helpers";

const AdminCampaigns_List = () => {
  // ----- Context -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  // ----- States -----
  const [open, setOpen] = useState(true);
  const [dataTableRows, setDataTableRows] = useState([]);
  const [reloadCampaigns, setReloadCampaigns] = useState(false);
  const [runningAgents, setRunningAgents] = useState([]);
  const [registered, setRegistered] = useState([]);
  const [status, setStatus] = useState(false);
  const [value, setValue] = useState("1");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteUUID, setDeleteUUID] = useState("");
  const [deleteName, setDeleteName] = useState("");

  //Variables

  const columns = [
    {
      field: "name",
      headerName: "Campaign Name",
      maxWidth: 150,
      flex: 2,
      renderCell: (params) => {
        let link = `/${params.row.customer}/reporting/campaign/${params.row.id}`;
        if (params.id !== params.value) {
          return <Typography>{params.row.name}</Typography>;
        }
        return <Link to={link}>{params.row.name}</Link>;
      },
    },
    {
      field: "endpoint",
      headerName: "Endpoint",
      maxWidth: 200,
      flex: 2,
      renderCell: (params) => {
        return (
          <Link
            to={`/${params.row.customer}/simulations/endpoints/detail/${params.endpoint_uuid}`}
          >
            {params.row.endpoint}
          </Link>
        );
      },
    },
    {
      field: "simulation",
      headerName: "Simulation",
      maxWidth: 200,
      flex: 2,
      renderCell: (params) => {
        const link = `/${params.row.customer}/simulations/${params.row.simulation_uuid}`;
        if (params.row.simulation_uuid) {
          return <Link to={link}>{params.row.simulation}</Link>;
        }
        return <Typography>{"archived simulation"}</Typography>;
      },
    },
    {
      field: "customer",
      headerName: "Customer",
      maxWidth: 200,
      flex: 1,
    },

    {
      field: "Execution_method",
      headerName: "Execution Method",
      maxWidth: 1500,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      maxWidth: 200,
      flex: 2,
      renderCell: (params) => {
        let icon,
          message,
          color,
          compClass = "";

        switch (params.row.status) {
          case "completed":
            icon = FaCheckCircle;
            color = COLORS.success.main;
            message = "Completed";
            break;

          case "ready":
            icon = FaHourglass;
            color = COLORS.primary.main;
            message = "Ready for Execution";
            break;

          case "retrieved":
            icon = FaHourglass;
            color = COLORS.info.main;
            message = "Retrieved for Execution";
            break;

          case "error":
            icon = FaTimesCircle;
            color = COLORS.error.main;
            message = "Failed on Execution";
            break;

          case "running":
            icon = FaSpinner;
            color = "orange";
            message = "Running";
            compClass = "spinner";
            break;

          case "partial":
            icon = FaCheckCircle;
            color = COLORS.yellow;
            message = "Partial Completion";
            break;
          default:
            break;
        }
        return (
          <>
            <Stack direction="row">
              {React.createElement(icon, {
                size: 20,
                color: color,
                className: compClass,
              })}
              <Typography sx={{ marginLeft: 1 }}>{message}</Typography>
            </Stack>
          </>
        );
      },
    },
    { field: "created", headerName: "Created", maxWidth: 140, flex: 1 },
    {
      field: "score",
      headerName: "Score",
      maxWidth: 70,
      flex: 1,
      renderCell: (params) => {
        let color = "success";
        if (params.row.score * 100 < 50) {
          color = "error";
        } else if (params.row.score * 100 < 75) {
          color = "warning";
        }
        return (
          <div style={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
              variant="determinate"
              value={params.row.score * 100}
              size={45}
              thickness={3}
              color={color}
            />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Typography variant="body2">
                {`${Math.round(params.row.score * 100)}%`}
              </Typography>
            </div>
          </div>
        );
      },
    },
    {
      field: "scoring_complete",
      headerName: "Fully Scored",
      maxWidth: 100,
      flex: 1,
      renderCell: (params) => {
        if (params.row.scoring_complete) {
          return (
            <Stack direction="row" alignContent="center" sx={{ marginLeft: 2 }}>
              <FaCheckCircle size={25} color={COLORS.success.main} />
            </Stack>
          );
        } else {
          return (
            <Stack direction="row" alignContent="center" sx={{ marginLeft: 2 }}>
              <FaTimesCircle size={25} color={COLORS.error.main} />
            </Stack>
          );
        }
      },
    },
    {
      field: "report",
      headerName: "Report",
      maxWidth: 60,
      flex: 1,
      renderCell: (params) => {
        let link = `/${current_customer.uuid}/reporting/campaign/${params.row.id}`;
        return params.id !== params.row.name ? (
          <Stack direction="row" marginLeft={1}>
            <Link to={link}></Link>
            <IconButton title={params.row.name}>
              <FaChartArea />
            </IconButton>
          </Stack>
        ) : null;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      maxWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack
            direction="row-reverse"
            sx={{ justifyContent: "space-between", width: "100%" }}
          >
            <IconButton
              title="Download Campaign Details"
              onClick={() => downloadCampaign(params)}
            >
              <FaDownload />
            </IconButton>
            <Link
              to={`/${params.row.customer}/reporting/matrix/${params.row.id}`}
            >
              <IconButton title="Report Attack Matrix">
                <FaChessBoard />
              </IconButton>
            </Link>
            <IconButton
              onClick={() => {
                setDeleteName(params.row.name);
                setDeleteUUID(params.row.campaign_uuid);
                setDeleteModal(true);
              }}
              title="Delete Campaign"
            >
              <FaTrashAlt color={COLORS.error.main} />
            </IconButton>
            {params.id !== params.row.name && (
              <>
                <IconButton
                  onClick={() => downloadCampaign(params)}
                  title="Download Campaign Details"
                >
                  <FaDownload />
                </IconButton>
                <Link
                  to={`/${params.row.customer}/reporting/matrix/${params.row.id}`}
                >
                  <IconButton title="Report Attack Matrix">
                    <FaChessBoard />
                  </IconButton>
                </Link>
              </>
            )}
          </Stack>
        );
      },
    },
  ];

  //Functions

  const downloadCampaign = async (params) => {
    const res = await fileRequest(
      `/react/api/${current_customer.uuid}/report/download/${params.row.campaign_uuid}/${params.row.id}`,
      accessToken,
      `${params.row.name}.csv`
    );
    if (res.status === 200) {
      return;
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const deleteCampaign = async () => {
    const res = await toast.promise(
      postRequest(`/react/api/${current_customer.uuid}/campaign/delete/${deleteUUID}`, accessToken),
      {
        pending: "Deleting Campaign",
        success: `Campaign Deleted`,
        error: "Something went wrong!",
      }
    );
    if (res.status === 200) {
      setDeleteModal(false);
      setReloadCampaigns((current) => !current);
    }
  };

  const populateDataGrid = (campaigns) => {
    const rows = campaigns.map((campaign) => {
      const executionMethod = getExecutionMethod(campaign);
      let id;
      if (campaign.status !== "ready") {
        id = campaign.uuid;
      } else {
        id = campaign.name;
      }
      return {
        id,
        campaign_uuid: campaign.uuid,
        endpoint_uuid: campaign.endpoint.uuid,
        name: campaign.name,
        customer: campaign.customer.display_name,
        endpoint: campaign.endpoint && campaign.endpoint.name,
        simulation:
          campaign.payload.simulation && campaign.payload.simulation.name,
        simulation_uuid:
          campaign.payload.simulation && campaign.payload.simulation.uuid,
        Execution_method: executionMethod,
        status: campaign.status,
        created: moment(campaign.created).format("M/D/YY,hh:mm a"),
        score: campaign.score,
        scoring_complete: campaign.scoring_complete,
        steps: campaign.steps,
        report: "",
        actions: "",
      };
    });
    setDataTableRows(rows);
  };

  // ----- ON PAGE LOAD -----

  useEffect(() => {
    const getEndpoints_Assess = async () => {
      const res = await getRequest(`/react/api/admin/campaigns`, accessToken);
      if (res.status === 200) {
        populateDataGrid(res.data.campaigns);
        setRunningAgents(res.data.running_agents);
        setRegistered(res.data.registered);
        setStatus(true);
      }
    };
    getEndpoints_Assess();
  }, [current_customer, accessToken, reloadCampaigns]);

  if (status === false) {
    return (
      <>
        <Typography align="center">
          <CircularProgress />{" "}
        </Typography>
      </>
    );
  } else {
    return (
      <>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/">Home</Link>
          <Typography color="text.primary">Administration</Typography>
          <Typography color="text.primary">Campaigns</Typography>
        </Breadcrumbs>

        <Collapse in={open}>
          <Alert
            sx={{ marginTop: 3 }}
            severity="info"
            variant="outlined"
            onClose={() => {
              setOpen(false);
            }}
          >
            {registered < 1 && (
              <Typography sx={{ fontWeight: "bold" }}>
                No agents currently registered.
              </Typography>
            )}

            {runningAgents < 1 && (
              <Typography sx={{ fontWeight: "bold" }}>
                No agents currently running. Please start the BlindSPOT agent on
                at least one system to start!
              </Typography>
            )}

            {runningAgents > 1 && (
              <Typography sx={{ fontWeight: "bold" }}>
                You have live agents reporting to the BlindSPOT platform, ready
                to kick off some simulations!
              </Typography>
            )}
          </Alert>
        </Collapse>
        <Paper sx={{ marginTop: "20px" }}>
          <TabContext value={value}>
            <Box>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="All Campaigns" value="1" />
                <Tab label="Completed" value="2" />
                <Tab label="Running" value="3" />
                <Tab label="Retrieved" value="4" />
                <Tab label="Partial" value="5" />
                <Tab label="Failed" value="6" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ p: 0 }}>
              <DataGridWithStyles
                name="admin-campaigns-list"
                autoHeight
                rows={dataTableRows}
                columns={columns}
              />
            </TabPanel>
            <TabPanel value="2" sx={{ p: 0 }}>
              <DataGridWithStyles
                name="admin-campaigns-list"
                autoHeight
                sx={{ borderRadius: "none" }}
                filterModel={{
                  items: [
                    {
                      columnField: "status",
                      value: "completed",
                    },
                  ],
                }}
                rows={dataTableRows}
                columns={columns}
              />
            </TabPanel>
            <TabPanel value="3" sx={{ p: 0 }}>
              <DataGridWithStyles
                name="admin-campaigns-list"
                autoHeight
                filterModel={{
                  items: [
                    {
                      columnField: "status",
                      value: "running",
                    },
                  ],
                }}
                rows={dataTableRows}
                columns={columns}
              />
            </TabPanel>
            <TabPanel value="4" sx={{ p: 0 }}>
              <DataGridWithStyles
                name="admin-campaigns-list"
                autoHeight
                filterModel={{
                  items: [
                    {
                      columnField: "status",
                      value: "retrieved",
                    },
                  ],
                }}
                rows={dataTableRows}
                columns={columns}
              />
            </TabPanel>
            <TabPanel value="5" sx={{ p: 0 }}>
              <DataGridWithStyles
                name="admin-campaigns-list"
                autoHeight
                filterModel={{
                  items: [
                    {
                      columnField: "status",
                      value: "partial",
                    },
                  ],
                }}
                rows={dataTableRows}
                columns={columns}
              />
            </TabPanel>
            <TabPanel value="6" sx={{ p: 0 }}>
              <DataGridWithStyles
                name="admin-campaigns-list"
                autoHeight
                filterModel={{
                  items: [
                    {
                      columnField: "status",
                      value: "error",
                    },
                  ],
                }}
                rows={dataTableRows}
                columns={columns}
              />
            </TabPanel>
          </TabContext>
        </Paper>
        {/* Delete Modal */}
        <FormModal open={deleteModal} setOpen={setDeleteModal}>
          <Box component="form">
            <Typography
              variant="h6"
              style={{ textAlign: "center", marginBottom: 4 }}
            >
              Are you sure you want to remove action {deleteName}?
            </Typography>
            <Stack direction="column" spacing={2}>
              <Button onClick={() => deleteCampaign()} variant="contained">
                Yes
              </Button>
            </Stack>
          </Box>
        </FormModal>
      </>
    );
  }
};

export default AdminCampaigns_List;
