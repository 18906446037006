import { LoadingButton } from "@mui/lab";
import {
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../Context/dataContext";
import { getRequest, postRequest } from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";


const ProjectReportSettings = (props) => {
  const { setSuperReport, setSuperClose, executiveSummary } = props;

  const [templates, setTemplates] = useState([]);
  const [projectReport, setProjectReport] = useState(props.projectReport);

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [isFormProcessing, setFormProcessing] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");

  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);
  // ----- FUNCTIONS -----

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitDisabled(true);
    setShowErrorMsg(false);
    setFormProcessing(true);
    const data = {
      report: projectReport,
      executiveSummary: executiveSummary,
      campaign_sankey: projectReport.campaign_sankey,
    };

    const result = await postRequest(
      `/react/api/${current_customer.uuid}/report/settings`,
      accessToken,
      data
    );

    if (result.status === 200) {
      setFormProcessing(false);
      setSubmitDisabled(false);
      setShowErrorMsg(false);
      setSuperReport(projectReport);
      setSuperClose(false);
    } else {
      setFormProcessing(false);
      setSubmitDisabled(false);
      setShowErrorMsg(true);
      setStatusMsg(result.data.message);
    }
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getTemplates = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/report/templates`,
        accessToken
      );
      if (res.status === 200) {
        setTemplates(res.data.templates);
      }
    };

    getTemplates();
  }, [current_customer, accessToken]);

  const handleToggleSankey = (e) => {
    setProjectReport({
      ...projectReport,
      campaign_sankey: e.target.checked, // Toggle campaign_sankey value
    });
  };

  return (
    <Stack>
      <Typography variant="h5">Edit {projectReport.name}</Typography>
      <Divider style={{ margin: "1rem 0" }} />

      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          {/* Name */}
          <TextField
            required
            value={projectReport.name}
            onChange={(e) =>
              setProjectReport({ ...projectReport, name: e.target.value })
            }
            label="Name"
          />
          {/* Template */}
          {templates[0] ? (
            <FormControl>
              <InputLabel required>Template</InputLabel>
              <Select
                value={
                  projectReport.template ? projectReport.template.uuid : ""
                }
                onChange={(e) =>
                  setProjectReport({
                    ...projectReport,
                    template: templates.find(
                      (temp) => temp.uuid === e.target.value
                    ),
                  })
                }
              >
                {templates.map((template) => (
                  <MenuItem value={template.uuid} key={template.uuid}>
                    {template.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}

          {/* Campaign Sankey Toggle */}
          <FormControlLabel
            control={
              <Switch
                checked={projectReport.campaign_sankey}
                onChange={handleToggleSankey}
              />
            }
            label="Campaign Sankey"
            labelPlacement="end"
          />
          <Typography
            sx={{ display: showErrorMsg ? "block" : "none" }}
            color="error"
            variant="body1"
          >
            Bad Request: {statusMsg}
          </Typography>
          <LoadingButton
            variant="contained"
            loading={isFormProcessing}
            disabled={submitDisabled}
            type="submit"
          >
            Submit
          </LoadingButton>
        </Stack>
      </form>
    </Stack>
  );
};

export default ProjectReportSettings;
