import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import moment from "moment/moment";
import { useContext, useEffect, useState } from "react";
import {
  FaBan,
  FaCrosshairs,
  FaExclamationTriangle,
  FaEyeSlash,
  FaFileCode,
  FaThumbsDown,
  FaThumbsUp,
} from "react-icons/fa";
import DataGridWithStyles from "../../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import { DataContext } from "../../../../Context/dataContext";
import { postRequest } from "../../../../Helpers/httpRequests";
import CampaignContext from "../provider/CampaignContext";

import { useCallback } from "react";
import { Link } from "react-router-dom";
import { COLORS } from "../../../../Styles/colors";
import { useAppSelector } from "../../../../Redux/app/hooks";

const ActionsFailed = () => {
  //States
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);
  const {
    executedUUID,
    securityTools,
    setRefresh,
    reload,
    modifiedThreshold,
    campaign,
    findings,
  } = useContext(CampaignContext);
  const role = useAppSelector((state) => state.user.role);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);

  //Column for Table
  const successColor = "#cefad0";
  const failColor = "#ffeaea";

  const configureColors = (thresholdName) => {
    let colors;
    switch (thresholdName) {
      case "blocked":
        colors = [successColor, failColor, failColor, failColor];
        break;
      case "alerted":
        colors = [successColor, successColor, failColor, failColor];
        break;
      case "logged":
        colors = [successColor, successColor, successColor, failColor];
        break;
      case "nothing":
        colors = [successColor, successColor, successColor, successColor];
        break;
      default:
        colors = ["#ffffff00", "#ffffff00", "#ffffff00", "#ffffff00"];
        break;
    }
    return colors;
  };

  const actionsColumns = [
    {
      field: "actions",
      headerName: "Actions",
      maxWidth: 300,
      flex: 5,
      renderCell: (params) => {
        const handleThresholdClick = (status, isActive) => {
          params.row.blocked = status === "blocked";
          params.row.alerted = status === "alerted";
          params.row.logged = status === "logged";
          params.row.nothing = status === "nothing";
          const lookupTable = {
            nothing: {
              nothing: true,
              blocked: true,
              alerted: true,
              logged: true,
            },
            blocked: {
              nothing: false,
              blocked: true,
              alerted: false,
              logged: false,
            },
            alerted: {
              nothing: false,
              blocked: true,
              alerted: true,
              logged: false,
            },
            logged: {
              nothing: false,
              blocked: true,
              alerted: true,
              logged: true,
            },
            No_Evidence: {
              nothing: true,
              blocked: true,
              alerted: true,
              logged: true,
            },
          };
          const paramsThreshold = params.row.thresholdName;
          const action = isActive ? "remove" : "add";
          if (action === "remove") {
            params.row[status] = false;
            params.row.threshold_met = false;
          } else {
            params.row.threshold_met =
              lookupTable[paramsThreshold][status] || false;
          }
          postRequest(
            `/react/api/${current_customer.uuid}/report/js-tags`,
            accessToken,
            {
              status: status,
              executedID: executedUUID,
              itemID: params.row.id,
              action: action,
            }
          ).then((res) => {
            if (res.status === 200) {
              setRefresh(true);
            }
          });

          modifiedThreshold.push({
            status: params.row.not_selected ? "" : params.row.thresholdName,
            executedID: executedUUID,
            itemID: params.row.id,
          });
        };
        const theme = createTheme({
          palette: {
            logged: {
              main: "#ECC010",
              contrastText: "#fff",
            },
          },
        });

        const btnColors = configureColors(params.row.thresholdName);

        return (
          <ThemeProvider theme={theme}>
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              size="large"
              fullWidth
            >
              <Tooltip title="blocked" placement="top" arrow>
                <Button
                  disabled={role === "View_Only"}
                  color="success"
                  variant={params.row.blocked ? "contained" : "outlined"}
                  sx={{
                    backgroundColor: params.row.blocked
                      ? "success"
                      : btnColors[0],
                  }}
                  onClick={(e) => {
                    e.persist();
                    handleThresholdClick("blocked", params.row.blocked);
                  }}
                >
                  {params.row.thresholdName === "blocked" ? (
                    <FaCrosshairs />
                  ) : (
                    <FaBan />
                  )}
                </Button>
              </Tooltip>
              <Tooltip title="Alerted" placement="top" arrow>
                <Button
                  disabled={role === "View_Only"}
                  color="primary"
                  variant={params.row.alerted ? "contained" : "outlined"}
                  sx={{
                    backgroundColor: params.row.alerted
                      ? "primary"
                      : btnColors[1],
                  }}
                  onClick={(e) => {
                    e.persist();
                    handleThresholdClick("alerted", params.row.alerted);
                  }}
                >
                  {params.row.thresholdName === "alerted" ? (
                    <FaCrosshairs />
                  ) : (
                    <FaExclamationTriangle />
                  )}
                </Button>
              </Tooltip>
              <Tooltip title="Logged" placement="top" arrow>
                <Button
                  disabled={role === "View_Only"}
                  color="logged"
                  variant={params.row.logged ? "contained" : "outlined"}
                  sx={{
                    backgroundColor: params.row.logged
                      ? "logged"
                      : btnColors[2],
                  }}
                  onClick={(e) => {
                    e.persist();
                    handleThresholdClick("logged", params.row.logged);
                  }}
                >
                  {params.row.thresholdName === "logged" ? (
                    <FaCrosshairs />
                  ) : (
                    <FaFileCode />
                  )}
                </Button>
              </Tooltip>
              <Tooltip title="No Evidence / Nothing" placement="top" arrow>
                <Button
                  disabled={role === "View_Only"}
                  color="error"
                  variant={params.row.nothing ? "contained" : "outlined"}
                  sx={{
                    backgroundColor: params.row.nothing
                      ? "error"
                      : btnColors[3],
                  }}
                  onClick={(e) => {
                    e.persist();
                    handleThresholdClick("nothing", params.row.nothing);
                  }}
                >
                  {params.row.thresholdName === "no evidence" ? (
                    <FaCrosshairs />
                  ) : (
                    <FaEyeSlash />
                  )}
                </Button>
              </Tooltip>
            </ButtonGroup>
          </ThemeProvider>
        );
      },
    },
    {
      field: "threshold_met",
      headerName: "Threshold Met",
      maxWidth: 120,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.threshold_met === true && (
              <>
                <IconButton>
                  <FaThumbsUp color={COLORS.success.main} />
                </IconButton>
                <Typography>Yes</Typography>
              </>
            )}
            {params.row.threshold_met === false && (
              <>
                <IconButton>
                  <FaThumbsDown color={COLORS.error.main} />
                </IconButton>
                <Typography>No</Typography>
              </>
            )}
            {params.row.threshold_met === null && <Typography></Typography>}
            {params.row.threshold_met === undefined && (
              <Typography></Typography>
            )}
          </>
        );
      },
    },
    {
      field: "security_tool",
      headerName: "Change Security Tool",
      minWidth: 160,
      flex: 1,
      renderCell: (params) => {
        let securityTool = params.row.security_tool;
        let row = params.row;
        const handleChange = async (event) => {
          params.row.securityTool = event.target.value;
          securityTool = event.target.value;

          let securitytooldata = securityTools.find(
            (tool) => tool.name === securityTool
          );

          const res = await postRequest(
            `/react/api/${current_customer.uuid}/report/tools/${executedUUID}/${params.row.id}/${securitytooldata.id}`,
            accessToken,
            {
              executeID: executedUUID,
              itemID: params.row.id,
              toolID: securitytooldata.id,
            }
          );

          if (res.status === 200) {
            setActions((prevActions) => {
              return prevActions.map((action) => {
                return action.id === params.id
                  ? { ...action, security_tool: securitytooldata }
                  : action;
              });
            });
          }
        };
        return (
          <FormControl sx={{ width: "100%" }}>
            <Select
              disabled={role === "View_Only"}
              style={{ opacity: role === "View_Only" ? 0.5 : 1 }}
              sx={{
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
              }}
              labelId="security-tool-select"
              id={"security-tool-select" + row.id}
              value={securityTool.name || ""}
              onChange={handleChange}
            >
              {securityTools &&
                securityTools.map((tool) => {
                  return (
                    <MenuItem key={tool.uuid} value={tool.name}>
                      {tool.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "action_name",
      headerName: "Action Name",
      minWidth: 600,
      flex: 1,
      renderCell: (params) => {
        return params.row.simulation_action !== null ||
          campaign?.payload?.cmd_output === "cs" ? (
          <Link
            to={`/${current_customer.uuid}/reporting/finding/${executedUUID}/${params.row.id}`}
          >
            {params.row.action_name || campaign.name}
          </Link>
        ) : (
          <Typography>{params.row.action_name}</Typography>
        );
      },
    },
    {
      field: "timestamp",
      headerName: "Timestamp",
      maxWidth: 120,
      flex: 1,
      renderCell: (params) => {
        return <div>{moment(params.row.timestamp).format("hh:mm:ss a")}</div>;
      },
    },
    {
      field: "detect_time",
      headerName: "Detect Time",
      maxWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const handleChange = async (event) => {
          const res = await postRequest(
            `/react/api/${current_customer.uuid}/report/campaign/add_detect_time/${params.row.id}/${executedUUID}`,
            accessToken,
            {
              executeID: executedUUID,
              itemID: params.row.id,
              detect_time: event.target.value,
            }
          );

          if (res.status === 200) {
            setActions((prevActions) => {
              return prevActions.map((action) => {
                return action.id === params.id
                  ? { ...action, detect_time: event.target.value }
                  : action;
              });
            });
          }
        };
        return (
          <>
            <input
              disabled={role === "View_Only"}
              style={{ opacity: role === "View_Only" ? 0.5 : 1, width: 17 }}
              onBlur={(event) => handleChange(event)}
              type="datetime-local"
              min={new Date(
                params.row.timestamp ? params.row.timestamp : Date.today
              )
                .toISOString()
                .slice(0, -8)}
            />
            {params.row.detect_time !== "" && (
              <div component="span" style={{ marginLeft: 3 }}>
                {moment(params.row.detect_time).format("MMM. D, YYYY, hh:mm a")}
              </div>
            )}
          </>
        );
      },
    },
    { field: "process_id", headerName: "Process ID", minWidth: 60, flex: 1 },
  ];

  // Functions
  const memoizedPopulateActions = useCallback((actions) => {
    const rows = actions
      .filter((a) => a.failed === true)
      .map((action) => {
        return {
          id: action.uuid,
          blocked: action.blocked,
          alerted: action.alerted,
          logged: action.logged,
          nothing: action.nothing,
          not_selected: !(
            action.alerted ||
            action.logged ||
            action.nothing ||
            action.blocked
          ),
          test_blocked: action.simulation_action?.blocked ?? false,
          test_alerted: action.simulation_action?.alerted ?? false,
          test_logged: action.simulation_action?.logged ?? false,
          test_nothing: action.simulation_action?.nothing ?? false,
          threshold_met: action.metThreshold,
          thresholdName: action.thresholdName,
          security_tool: action.security_tool || "None",
          action_name: action.name,
          timestamp: action.timestamp,
          detect_time: action.detect_time || "",
          process_id: action.process || "None",
          simulation_action:
            (action.simulation_action && action.simulation_action) || null,
        };
      });
    setActions(rows);
  }, []);

  // On Page Load
  useEffect(() => {
    const fetchActions = async () => {
      try {
        if (findings !== null && findings.length > 0) {
          memoizedPopulateActions(findings);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchActions();
  }, [
    executedUUID,
    accessToken,
    reload,
    memoizedPopulateActions,
    current_customer,
    findings,
  ]);

  return (
    <>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography align="left" color="error">
          Error: {error.message}
        </Typography>
      ) : (
        actions.length > 0 && (
          <Paper sx={{ padding: 2, marginTop: 2 }}>
            <Stack spacing={2}>
              <Typography fontWeight="bold" variant="h6">
                The following actions failed during the campaign
              </Typography>
              <DataGridWithStyles
                name="reports-campaign-details-fails"
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                noExport
                autoHeight
                rows={actions}
                columns={actionsColumns}
              />
            </Stack>
          </Paper>
        )
      )}
    </>
  );
};

export default ActionsFailed;
