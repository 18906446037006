import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  Alert,
  Box,
  Breadcrumbs,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  createTheme,
  Menu,
} from "@mui/material";
import JSZip from "jszip";
import { useContext, useEffect, useState } from "react";
import {
  FaCircle,
  FaCog,
  FaFileUpload,
  FaInfo,
  FaPlay,
  FaPlus,
  FaSave,
  FaStop,
  FaTimes,
  FaUpload,
  FaEllipsisV,
} from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import "react-select-search/style.css";
import HowToWithHtml from "../../../Components/HowTos/HowToWithHtml";
import SimBuilderHowToHtml from "../../../Components/HowTos/SimBuilder/SimBuilderHowToHtml";
import FormModal from "../../../Components/Modals/FormModal";
import { DataContext } from "../../../Context/dataContext";
import { getRequest, postRequest } from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";
import { COLORS } from "../../../Styles/colors";
import AddActionDrawer from "./AddActionDrawer";
import { ArchitectureSpecificFileUpload } from "./ArchitectureSpecificFileUpload";
import DraggableList from "./DraggableList";
import EditActionDrawer from "./EditActionDrawer";
import ConflictModal from "./Modals/ConflictModal";
import DeleteModal from "./Modals/DeleteModal";
import JsonUploadModal from "./Modals/JsonUploadModal";
import OsChangeModal from "./Modals/OsChangeModal";
import TestSettingModal from "./Modals/TestSettingModal";
import UploadFileModal from "./Modals/UploadFileModal";
import {
  EditAction,
  getItemStyle,
  handleDeleteFile,
  handleDownloadFile,
  onDragEnd,
  saveSim,
  setSimInfo,
  testSim,
} from "./functions";
import { modalStyle } from "./styles";
import UploadUniFileModal from "./Modals/UploadUniFileModal";
import CampaignLevelVariables from "./CampaignLevelVariables";
import { useNavigate } from "react-router-dom";

const BuildNewPayload = () => {
  const navigate = useNavigate();

  // ----- STATES && CONTEXT -----
  const { sim_uuid } = useParams();
  const user = useAppSelector((state) => state.user);
  const current_customer = useAppSelector((state) => state.customer);
  const { accessToken } = useContext(DataContext);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addActionModal, setAddActionModal] = useState(false);
  const [edittedActionModal, setEdittedActionModal] = useState(false);
  const [deleteUUID, setDeleteUUID] = useState("");
  const [uploadModal, setUploadModal] = useState(false);
  const [uploadUnifileModal, setUploadUniFileModal] = useState(false);
  const [osChangeModal, setOsChangeModal] = useState(false);
  const [tempOs, setTempOs] = useState(false);
  const [settingModal, setSettingsModal] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [options, setOptions] = useState([]);
  const [allOptions, setAllOptions] = useState([]);
  const [techniques, setTechniques] = useState([]);
  const [initialEdittedAction, setInitialEdittedAction] = useState([]);
  const [addActionIndex, setAddActionIndex] = useState(0);
  const [edittedActionIndex, setEdittedActionIndex] = useState();
  const [editPage, setEditPage] = useState(false);
  const [testing, setTesting] = useState(false);
  const [pollInterval, setPollInterval] = useState("");
  const [howTo, setHowTo] = useState(false);
  const [testingResults, setTestingResults] = useState([]);
  const [testHistory, setTestHistory] = useState([]);
  const [piTargets, setPiTargets] = useState([]);
  const [maxSizeExceeded, setMaxSizeExceeded] = useState(false);
  const [uniFiles, setUniFiles] = useState([]);
  const [data, setData] = useState({
    simName: "",
    simDesc: "",
    input_files: [],
    actions: [],
    category: "",
    platform: "windows",
    endpoint: "",
    execMethod: "exe",
    piMethod: "",
    piTarget: "",
    sleepTime: 2,
    maxRuntime: 30,
    transform: "",
    architecture: "64",
    inMemory: false,
    obfuscate: false,
    alert_validation: false,
  });

  const [campaignLevelVariables, setCampaignLevelVariables] = useState([]);
  const [edittedAction, setEdittedAction] = useState({
    module: "",
    ttps: [],
    arguments: [],
    note: "",
    clean_up: false,
    expected_outcome: null,
    time_out: null,
  });
  const [actionData, setActionData] = useState({
    module: "",
    ttps: [],
    arguments: [],
    note: "",
    time_out: 60,
    clean_up: false,
    expected_outcome: null,
  });
  const [argEditIndex, setArgEditIndex] = useState(-1);
  const [argInputValue, setArgInputValue] = useState();
  const [endpoints, setEndpoints] = useState([]);
  const [changesMade, setChangesMade] = useState(false);
  const [osType, setOsType] = useState("windows");
  const [conflict, setConflict] = useState(false);
  const [conflictBy, setConflictBy] = useState(null);
  const [conflictTime, setConflictTime] = useState(false);
  const [openArchModal, setOpenArchModal] = useState(false);
  const [openFileModal, setOpenFileModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const execMethod = [
    {
      value: "exe",
      name: "Binary Executable",
    },
    {
      value: "dll",
      name: "Signed Proxy Execution",
    },
    {
      value: "sh",
      name: "Remote Process Injection",
    },
    {
      value: "local",
      name: "Local Process Injection",
    },
  ];

  const architectures = [
    {
      value: "64",
      name: "64-bit",
    },
    {
      value: "32",
      name: "32-bit",
    },
  ];

  // There actually isn't a model for this. It's hard-coded in the models.py so I'll hardcode them here too for now
  const transformMethods = [
    "CreateFiber",
    "CreateThread",
    "CreateThreadNative",
    "EtwpCreateEtwThread",
    "NtQueueApcThreadEx-Local",
    "CreateThreadHellsGate",
  ];

  const linuxExecMethod = [
    {
      value: "exe",
      name: "Executable",
    },
  ];

  const piMethods = [
    "CreateRemoteThread",
    "NtCreateRemoteThread",
    "QueueUserAPC",
    "NtQueueUserAPC",
    "RtlCreateUserThread",
  ];
  const platforms = ["windows", "mac", "linux"];
  const theme = createTheme({
    palette: {
      logged: {
        main: "#ECC010",
        contrastText: "#fff",
      },
    },
  });

  const getEndpoints = async () => {
    // get the list of endpoints and targets
    const resEndpoints = await getRequest(
      `/react/api/${current_customer.uuid}/active_endpoints?license=True`,
      accessToken
    );
    if (resEndpoints.status === 200) {
      setEndpoints(resEndpoints.data.endpoints);
    }
  };
  const updateContributor = async () => {
    await postRequest(
      `/react/api/${current_customer.uuid}/payload_builder/update_contributor/${sim_uuid}`,
      accessToken
    );
  };

  useEffect(() => {
    let canceled = false; // A flag to track whether the effect is canceled
    const checkFileSizes = async () => {
      const zip = new JSZip();
      if (data.input_files && Array.isArray(data.input_files)) {
        for (const file of data.input_files) {
          const fileBlob = new Blob([file.content]); // Create a Blob from the content
          zip.file(file.name, fileBlob);
        }
      }
      try {
        // Wait for zip generation to complete and get the zip content
        const zipContent = await zip.generateAsync({ type: "base64" });

        if (!canceled) {
          const maxFileSize = 41943040;
          if (zipContent.length > maxFileSize) {
            setMaxSizeExceeded(true);
          } else {
            setMaxSizeExceeded(false);
          }
        }
      } catch (error) {
        console.error("Error generating zip content:", error);
      }
    };
    checkFileSizes();
    return () => {
      canceled = true;
    };
  }, [data.input_files]);

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getSimData = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/payload_builder/getSim/${sim_uuid}`,
        accessToken
      );
      if (res.status === 200) {
        setSimInfo(res.data.sim_json, setData);
        const inputFiles = res.data.files;
        setData((prevData) => {
          const updatedSimData = {
            ...prevData,
            input_files: inputFiles.map((file) => {
              const byteCharacters = atob(file.content); // Decode base64
              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              // Calculate the size in KB from the byteArray
              const sizeKB = (byteArray.length / 1024).toFixed(2);
              return {
                name: file.name,
                content: byteArray,
                size: sizeKB,
                uploaded_by: file.comment?.uploaded_by,
                uploaded_date: file.comment?.uploaded_date,
              };
            }),
            platform: "windows",
            endpoint: "",
            execMethod: "exe",
            piMethod: "",
            piTarget: "",
            sleepTime: 2,
            maxRuntime: 30,
            transform: "",
            architecture: "64",
            inMemory: false,
            obfuscate: false,
          };
          return updatedSimData;
        });

        if (res.data.sim_json.build_args) {
          setCampaignLevelVariables(res.data.sim_json.build_args);
        }
      }
    };

    const getUniFiles = async () => {
      try {
        const res = await getRequest(
          `/react/api/${current_customer.uuid}/payload_builder/uniFile`,
          accessToken
        );
        if (res.status === 200) {
          const files = res.data.files.map((file) => {
            const byteCharacters = atob(file.file_string); // Decode base64
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            // Calculate the size in KB from the byteArray
            const sizeKB = (byteArray.length / 1024).toFixed(2);
            return {
              name: file.name,
              content: byteArray,
              size: sizeKB,
            };
          });
          setUniFiles(files);
        }
      } catch (error) {
        console.error("Failed to fetch universal files:", error);
      }
    };

    const getTechniques = async () => {
      // get the list of techniques
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/payload_builder/build`,
        accessToken
      );
      if (res.status === 200) {
        // setEndpoints(res.data.endpoints);
        setAllOptions(res.data.options);
        setTechniques(res.data.techs);
      }
    };
    const getPiTargets = async () => {
      const resPiTargets = await getRequest(
        `/react/api/${current_customer.uuid}/pi-targets`,
        accessToken
      );
      if (resPiTargets.status === 200) {
        setPiTargets(resPiTargets.data.pi_targets);
      }
    };
    getUniFiles();
    getTechniques();
    getPiTargets();
    if (sim_uuid) {
      getSimData();
    }
  }, [accessToken, reloadPage, sim_uuid, current_customer.uuid]);

  useEffect(() => {
    // This function will run whenever osType changes
    const filterOptionsByOsType = () => {
      if (allOptions && allOptions.modules) {
        const filteredModules = allOptions.modules.filter((moduleWrapper) => {
          const module = moduleWrapper.module;
          return (
            module &&
            module.os &&
            (module.os.includes(osType) || module.os.includes("all"))
          );
        });
        setOptions(filteredModules);
      }
    };

    filterOptionsByOsType();
  }, [osType, allOptions]);

  // Prompt the user when attempting to leave the page to save changes
  window.addEventListener("beforeunload", (e) => {
    if (changesMade) {
      e.preventDefault();
      e.returnValue =
        "You have unsaved changes. Are you sure you want to leave without saving?";
    }
  });
  window.addEventListener("unload", () => {
    if (changesMade) {
      setChangesMade(false);
    }
  });

  return (
    <Stack sx={{ display: "flex", maxHeight: "83vh" }}>
      {/* Fixed Content */}
      <Box sx={{ height: 150, marginBottom: 1 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.primary">Beta</Typography>
          <Link
            className="text-links"
            to={`/${current_customer.uuid}/beta/payload-builder`}
          >
            Simulation Builder
          </Link>
          <Typography color="text.primary">Build New</Typography>
        </Breadcrumbs>
        <Box
          sx={{
            marginTop: 1,
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Typography variant="h3">Build New Simulation</Typography>
          <Box display="flex" flexDirection="row">
            <Tooltip title="Options">
              <IconButton
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
              >
                <FaEllipsisV size={30} />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
            >
              {user.superuser && (
                <MenuItem
                  onClick={() => {
                    setUploadModal(true);
                    setAnchorEl(null);
                  }}
                  disabled={testing}
                >
                  <FaUpload size={20} style={{ marginRight: "10px" }} />
                  Upload Existing Payload
                </MenuItem>
              )}
              {user.superuser && (
                <MenuItem
                  onClick={() => {
                    setUploadUniFileModal(true);
                    setAnchorEl(null);
                  }}
                  disabled={testing}
                >
                  <FaFileUpload size={20} style={{ marginRight: "10px" }} />
                  Upload Universal File
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  setSettingsModal(true);
                  setAnchorEl(null);
                }}
                disabled={testing}
              >
                <FaCog size={20} style={{ marginRight: "10px" }} />
                Test Settings
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setHowTo(true);
                  setAnchorEl(null);
                }}
                disabled={testing}
              >
                <FaInfo size={20} style={{ marginRight: "10px" }} />
                How To
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", marginTop: 2.5 }}>
          <Typography sx={{ marginRight: 1 }} variant="h5">
            Simulation Details
          </Typography>
          {/* Select Os Type */}
          <FormControl sx={{ maxWidth: 150, marginTop: -1.5 }}>
            <InputLabel>OS Type</InputLabel>
            <Select
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none", // Remove the border
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none", // Remove the border on hover
                },
              }}
              disabled={testing}
              value={osType}
              onChange={(e) => {
                setOsChangeModal(true);
                setTempOs(e.target.value);
              }}
              label="OS Type"
              variant="outlined"
            >
              <MenuItem value="windows">Windows</MenuItem>
              <MenuItem value="linux">Linux</MenuItem>
              <MenuItem value="mac">Mac</MenuItem>
            </Select>
          </FormControl>
          {data.actions.length > 0 && (
            <>
              {/* Endpoint */}
              <FormControl sx={{ minWidth: 200, marginTop: -1.5 }}>
                <InputLabel required>Endpoint for Testing</InputLabel>
                <Select
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none", // Remove the border
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none", // Remove the border on hover
                    },
                  }}
                  required
                  value={data.endpoint || ""}
                  onClick={() => getEndpoints()}
                  onChange={(e) =>
                    setData({ ...data, endpoint: e.target.value })
                  }
                  label=""
                  variant="outlined"
                >
                  {endpoints && endpoints.length > 0 ? (
                    endpoints
                      .sort((a, b) => {
                        if (a.active && !b.active) {
                          return -1;
                        } else if (!a.active && b.active) {
                          return 1;
                        } else {
                          return 0;
                        }
                      })
                      .map((endpoint) => {
                        const active = endpoint.active;
                        const color = active
                          ? COLORS.success.light
                          : COLORS.secondary.dark;
                        return endpoint.host_os.toLowerCase() ? (
                          <MenuItem key={endpoint.uuid} value={endpoint.uuid}>
                            <Stack direction={"row"} spacing={"1rem"}>
                              <FaCircle color={color} />
                              <Typography>{endpoint.name}</Typography>
                            </Stack>
                          </MenuItem>
                        ) : null;
                      })
                  ) : (
                    <MenuItem disabled>
                      <Alert severity="warning" sx={{ width: "100%" }}>
                        No active endpoints available
                      </Alert>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </>
          )}
          {data.actions.length > 0 && (
            <>
              <Tooltip title="Run Test">
                <IconButton
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top
                    testSim(
                      data,
                      campaignLevelVariables,
                      accessToken,
                      current_customer,
                      setTesting,
                      setTestingResults,
                      setPollInterval
                    );
                  }}
                  disabled={
                    maxSizeExceeded ||
                    testing ||
                    data.endpoint === "" ||
                    data.simDesc === "" ||
                    data.simName === "" ||
                    data.platform === ""
                  }
                  variant="contained"
                  sx={{ marginTop: -2 }}
                >
                  {testing ? (
                    <CircularProgress size={25} color="inherit" />
                  ) : (
                    <FaPlay
                      size={25}
                      style={{
                        color:
                          maxSizeExceeded ||
                          testing ||
                          data.endpoint === "" ||
                          data.simDesc === "" ||
                          data.simName === "" ||
                          data.platform === ""
                            ? COLORS.error.main
                            : COLORS.success.main,
                      }}
                    />
                  )}
                </IconButton>
              </Tooltip>
              {testing && (
                <Tooltip title="End Test">
                  <IconButton
                    onClick={() => {
                      clearInterval(pollInterval);
                      setTesting(false);
                    }}
                    sx={{ marginTop: -1 }}
                  >
                    <FaStop size={25} color={COLORS.error.main} />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title="Save Sim">
                <IconButton
                  disabled={
                    maxSizeExceeded ||
                    testing ||
                    data.simDesc === "" ||
                    data.simName === "" ||
                    data.platform === "" ||
                    current_customer === undefined
                  }
                  onClick={() => {
                    saveSim(
                      data,
                      accessToken,
                      setData,
                      setReloadPage,
                      navigate,
                      setChangesMade,
                      setConflict,
                      setConflictBy,
                      setConflictTime,
                      current_customer.uuid,
                      campaignLevelVariables
                    );
                  }}
                  variant="contained"
                  sx={{ marginTop: -1, marginLeft: 2 }}
                >
                  <FaSave
                    size={25}
                    color={
                      changesMade ? COLORS.primary.main : COLORS.secondary.main
                    }
                  />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      </Box>

      <Box sx={{ overflowY: "auto" }}>
        {/* Build New Payload Modal */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            maxWidth: "100%",
          }}
        >
          <Box
            className="left-box"
            sx={{ paddingRight: 1, width: "50%", flex: 1, marginTop: 1 }}
          >
            {/* FORM */}
            <form>
              <Stack spacing={1.5}>
                {/* Simulation Name */}
                <FormControl>
                  <TextField
                    required
                    value={data.simName}
                    onChange={(e) => {
                      setData((data) => ({
                        ...data,
                        simName: e.target.value,
                      }));
                      setChangesMade(true);
                    }}
                    type="text"
                    label="Simulation Name"
                    helperText=""
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                  />
                  {data.simName.length >= 50 && (
                    <div style={{ color: COLORS.warning.main, marginLeft: 10 }}>
                      Maximum character limit reached (50 characters)
                    </div>
                  )}
                </FormControl>

                {/* Simulation Description */}
                <FormControl>
                  <TextField
                    multiline
                    rows={4}
                    value={data.simDesc}
                    onChange={(e) => {
                      setData((data) => ({
                        ...data,
                        simDesc: e.target.value,
                      }));
                      setChangesMade(true);
                    }}
                    type="text"
                    label="Simulation Description"
                    helperText=""
                    variant="outlined"
                  />
                </FormControl>

                {/* Campaign Level Variables */}
                <Stack>
                  <CampaignLevelVariables
                    campaignLevelVariables={campaignLevelVariables}
                    setCampaignLevelVariables={setCampaignLevelVariables}
                  />
                </Stack>

                {/*Universal Files */}
                {/* {uniFiles.length > 0 && (
                  <Stack>
                    <Box>
                      <Typography variant="h5" component={"span"}>
                        Universal Files
                      </Typography>
                      <Stack spacing={1}>
                        {uniFiles.length > 0 && (
                          <>
                            <ul>
                              {uniFiles.map((file, index) => {
                                return (
                                  <li key={index}>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                    >
                                      <Typography
                                        className="text-links"
                                        onClick={() => handleDownloadFile(file)}
                                      >
                                        {file.name ? file.name : "Unnamed File"}
                                      </Typography>
                                      {file.size && (
                                        <Typography>
                                          ({file.size} KB)
                                        </Typography>
                                      )}
                                    </Stack>
                                  </li>
                                );
                              })}
                            </ul>
                          </>
                        )}
                      </Stack>
                      <Divider sx={{ marginTop: 1 }} />
                    </Box>
                  </Stack>
                )} */}

                {/* File upload */}
                <Stack>
                  <Box>
                    <Typography variant="h5" component={"span"}>
                      File Upload
                    </Typography>
                    <Tooltip title="Upload a File" sx={{ marginBottom: 1 }}>
                      <IconButton
                        disabled={testing}
                        onClick={() => setOpenFileModal(true)}
                      >
                        <FaFileUpload
                          size={22}
                          color={
                            testing
                              ? COLORS.secondary.main
                              : COLORS.primary.main
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <Divider sx={{ marginTop: -1 }} />
                  </Box>
                </Stack>
                <Stack spacing={1}>
                  {data.input_files.length > 0 && (
                    <>
                      <ul>
                        {data.input_files.map((file, index) => {
                          const isFileNameUsed = data.actions.some((action) =>
                            action.arguments.some(
                              (arg) =>
                                arg.includes(file.name) ||
                                arg.includes(file.alias)
                            )
                          );
                          return (
                            <li key={index}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                              >
                                <Typography
                                  className="text-links"
                                  onClick={() => handleDownloadFile(file)}
                                >
                                  {file.name ? file.name : "Unnamed File"}
                                </Typography>
                                {file.size && (
                                  <Typography>({file.size} KB)</Typography>
                                )}
                                {file.uploaded_by && file.uploaded_date && (
                                  <Typography>
                                    {file.uploaded_by} - {file.uploaded_date}
                                  </Typography>
                                )}
                                {file.alias && (
                                  <Typography>
                                    Aliased as: {file.alias}
                                  </Typography>
                                )}
                                <IconButton
                                  disabled={testing}
                                  size="small"
                                  onClick={() =>
                                    handleDeleteFile(
                                      index,
                                      setData,
                                      setChangesMade
                                    )
                                  }
                                >
                                  <FaTimes />
                                </IconButton>
                                {!isFileNameUsed && (
                                  <Typography
                                    sx={{ color: COLORS.warning.main }}
                                  >
                                    Warning - Unused file
                                  </Typography>
                                )}
                              </Stack>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                </Stack>
                {maxSizeExceeded && (
                  <Alert sx={{ width: "50%" }} severity="error">
                    File size limit exceeded. Please upload a smaller file or
                    remove a file
                  </Alert>
                )}

                {testHistory.length > 0 && (
                  <>
                    <Typography>Test Run History</Typography>
                    {testHistory.map((entry, index) => (
                      <Typography
                        className="text-links"
                        key={index}
                        onClick={() => {
                          // setData(entry[0]);
                          setTestingResults(entry[1]);
                        }}
                      >
                        Last Test at {entry[2]}
                      </Typography>
                    ))}
                  </>
                )}
              </Stack>
            </form>
          </Box>
        </Box>

        <Typography variant="h5">
          Current Actions
          <Tooltip title="Drag and Drop Active">
            <IconButton aria-label="Drag and Drop">
              <DragIndicatorIcon fontSize="large" />
            </IconButton>
          </Tooltip>
          {data.actions.length >= 40 && (
            <Alert sx={{ width: "50%", marginBottom: 2 }} severity="info">
              Action Limit has been reached!
            </Alert>
          )}
        </Typography>
        {data.actions.length === 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              maxWidth: "100%",
            }}
          >
            <Tooltip title={`Add Action at step 1`}>
              <IconButton
                sx={{ fontSize: 30 }}
                onClick={() => {
                  setAddActionModal(true);
                }}
              >
                <FaPlus sx={{ marginTop: 1 }} color={COLORS.info.main} />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        <Stack>
          <DraggableList
            data={data}
            setData={setData}
            setAddActionIndex={setAddActionIndex}
            setAddActionModal={setAddActionModal}
            setEdittedActionModal={setEdittedActionModal}
            setDeleteUUID={setDeleteUUID}
            setDeleteModal={setDeleteModal}
            setTestHistory={setTestHistory}
            EditAction={EditAction}
            setTestingResults={setTestingResults}
            testingResults={testingResults}
            testing={testing}
            setEdittedAction={setEdittedAction}
            setInitialEdittedAction={setInitialEdittedAction}
            setEditPage={setEditPage}
            setEdittedActionIndex={setEdittedActionIndex}
            getItemStyle={getItemStyle}
            onDragEnd={onDragEnd}
          />
        </Stack>
        {/* Add Action Drawer */}
        <AddActionDrawer
          addActionModal={addActionModal}
          setAddActionModal={setAddActionModal}
          addActionIndex={addActionIndex}
          setAddActionIndex={setAddActionIndex}
          data={data}
          setData={setData}
          actionData={actionData}
          setActionData={setActionData}
          options={options}
          argEditIndex={argEditIndex}
          setArgEditIndex={setArgEditIndex}
          setChangesMade={setChangesMade}
          techniques={techniques}
          theme={theme}
          argInputValue={argInputValue}
          setArgInputValue={setArgInputValue}
          testingResults={testingResults}
          setTestHistory={setTestHistory}
          edittedAction={edittedAction}
          uniFiles={uniFiles}
          user={user}
        />
        {/* Editted Action Drawer */}
        {editPage && (
          <>
            <EditActionDrawer
              data={data}
              setData={setData}
              actionData={actionData}
              setActionData={setActionData}
              options={options}
              argEditIndex={argEditIndex}
              setArgEditIndex={setArgEditIndex}
              setChangesMade={setChangesMade}
              techniques={techniques}
              theme={theme}
              argInputValue={argInputValue}
              setArgInputValue={setArgInputValue}
              testingResults={testingResults}
              setTestHistory={setTestHistory}
              edittedAction={edittedAction}
              setEdittedAction={setEdittedAction}
              edittedActionModal={edittedActionModal}
              initialEdittedAction={initialEdittedAction}
              setEditPage={setEditPage}
              setEdittedActionModal={setEdittedActionModal}
              edittedActionIndex={edittedActionIndex}
              uniFiles={uniFiles}
              user={user}
            />
          </>
        )}

        {/* Upload New Payload Modal */}
        <JsonUploadModal
          uploadModal={uploadModal}
          setUploadModal={setUploadModal}
          setData={setData}
        />

        {/* Delete Modal */}
        <DeleteModal
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          deleteUUID={deleteUUID}
          data={data}
          setReloadPage={setReloadPage}
        />

        {/*Test Settings Modal*/}
        <TestSettingModal
          settingModal={settingModal}
          setSettingsModal={setSettingsModal}
          data={data}
          setData={setData}
          setChangesMade={setChangesMade}
          linuxExecMethod={linuxExecMethod}
          execMethod={execMethod}
          transformMethods={transformMethods}
          platforms={platforms}
          architectures={architectures}
          piMethods={piMethods}
          piTargets={piTargets}
        />

        {/*Open Up How to Modal*/}
        <HowToWithHtml
          htmlContent={<SimBuilderHowToHtml />}
          open={howTo}
          setOpen={setHowTo}
        />

        {/* Conflict Modal */}
        <ConflictModal
          conflict={conflict}
          setConflict={setConflict}
          conflictBy={conflictBy}
          conflictTime={conflictTime}
          updateContributor={updateContributor}
          setReloadPage={setReloadPage}
          data={data}
          setChangesMade={setChangesMade}
          current_customer={current_customer.uuid}
        />

        {/* Os Change Modal */}
        <OsChangeModal
          osChangeModal={osChangeModal}
          setOsChangeModal={setOsChangeModal}
          setOsType={(type) => {
            setOsType(type);
            setData((prevData) => ({
              ...prevData,
              platform: type,
            }));
          }}
          tempOs={tempOs}
          setTempOs={setTempOs}
          setActionData={setActionData}
          setData={setData}
        />

        {/* Architecture Specific Files */}
        <FormModal
          sx={modalStyle}
          open={openArchModal}
          setOpen={setOpenArchModal}
        >
          <Box>
            <ArchitectureSpecificFileUpload
              simOS={osType}
              setData={setData}
              data={data}
            />
          </Box>
        </FormModal>
        {/* Upload File Modal */}
        <FormModal
          sx={{ width: "30%", maxHeight: "70%" }}
          open={openFileModal}
          setOpen={setOpenFileModal}
        >
          <Box>
            <UploadFileModal
              setChangesMade={setChangesMade}
              data={data}
              setData={setData}
              setOpenFileModal={setOpenFileModal}
              simOS={osType}
            />
          </Box>
        </FormModal>

        {/* Upload Universal File Modal */}
        <FormModal
          sx={{ width: "30%", maxHeight: "70%" }}
          open={uploadUnifileModal}
          setOpen={setUploadUniFileModal}
        >
          <Box>
            <UploadUniFileModal
              setChangesMade={setChangesMade}
              data={data}
              setData={setData}
              setOpenFileModal={setUploadUniFileModal}
              setUniFiles={setUniFiles}
            />
          </Box>
        </FormModal>
      </Box>
    </Stack>
  );
};

export default BuildNewPayload;
