import { Stack } from "@mui/material";
import { useState } from "react";
import Breadcrumbs from "./Components/Breadcrumbs";
import Calendar from "./Components/Calendar";
import { Checklist } from "./Components/Checklist";
import DataSelector from "./Components/DataSelector";
import Endpoints from "./Components/Endpoints";
import MTTD from "./Components/MTTD";
import MTTR from "./Components/MTTR";
import StatRow from "./Components/StatRow";
import Provider from "./Provider/Provider";

const AVOverview = () => {

  const [csvData, setCsvData] = useState({})

  return (
    <Provider>
      <Stack spacing={2}>
        <Breadcrumbs csvData={csvData} />
        <DataSelector />
        <StatRow setCsvData={setCsvData} />
        <Calendar />
        <Stack direction="row" spacing={2}>
          <Endpoints />
          <MTTR setCsvData={setCsvData} />
        </Stack>
        <Stack direction="row" spacing={2}>
          <MTTD setCsvData={setCsvData} />
        </Stack>
        <Checklist />
      </Stack>
    </Provider>
  );
};

export default AVOverview;
