import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Chip,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import {
  FaBan,
  FaExclamationTriangle,
  FaEyeSlash,
  FaFileCode,
  FaPlus,
} from "react-icons/fa";
import SelectSearch from "react-select-search";
import "react-select-search/style.css";
import { COLORS } from "../../../Styles/colors";
import ExpandableText from "./ExpandableText";
import InputTypeSelector from "./InputTypeSelector";
import { PaddedFileModule } from "./PaddedFileModule";
import {
  editAction,
  embedHandleChange,
  embedUniFile,
  handleArgChipClick,
  handleChangeEditModule,
  handleEditArgdelete,
  handleEditNoteChange,
  handleEditOptionalArgumentsClick,
  handleEditReqOptChange,
  handleEditTechniqueChangeAdd,
  handleEditTechniqueChangeRemove,
  handleEdittedActionClose,
} from "./functions";
import classes from "./style.module.css";
import { chipStylesArgs } from "./styles";

const EditActionDrawer = ({
  data,
  setData,
  actionData,
  setActionData,
  options,
  argEditIndex,
  setArgEditIndex,
  setChangesMade,
  techniques,
  theme,
  argInputValue,
  setArgInputValue,
  testingResults,
  setTestHistory,
  edittedAction,
  setEdittedAction,
  edittedActionModal,
  initialEdittedAction,
  setEditPage,
  setEdittedActionModal,
  edittedActionIndex,
  uniFiles,
  user,
}) => {
  return (
    <>
      <Drawer
        open={edittedActionModal}
        anchor="right"
        onClose={() => handleEdittedActionClose(setEdittedActionModal)}
        variant="persistent"
        sx={{
          width: "41%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "41%",
            boxSizing: "border-box",
          },
        }}
      >
        {/* Fixed Content */}
        <Stack
          sx={{ padding: 2, marginTop: 7 }}
          direction="row"
          alignItems="center" // Align items vertically
          justifyContent="space-between" // Align elements at the ends
        >
          <Typography variant="h5">
            Edit Action {edittedActionIndex + 1} - Active
          </Typography>
          <ButtonGroup
            sx={{
              "& .MuiButton-root": {
                marginRight: "0.5rem",
              },
            }}
          >
            {initialEdittedAction !== edittedAction && (
              <>
                {/* Edit Action */}
                <Button
                  disabled={
                    data.actions.length >= 40 ||
                    edittedAction.arguments.some((arg) =>
                      arg.startsWith("(")
                    ) ||
                    edittedAction.module === "" ||
                    (edittedAction.module === "run" &&
                      edittedAction.arguments.length === 0)
                  }
                  variant="contained"
                  onClick={() =>
                    editAction(
                      testingResults,
                      data,
                      setTestHistory,
                      setEditPage,
                      setData,
                      setActionData,
                      setChangesMade,
                      edittedActionIndex,
                      edittedAction
                    )
                  }
                >
                  Save Edited Action
                </Button>
              </>
            )}
            {/* Cancel */}
            <Button
              variant="outlined"
              onClick={() => {
                setEditPage(false);
              }}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Stack>

        {/* Scrollable Content */}

        <Box sx={{ maxHeight: "calc(100% - 175px)", overflowY: "auto" }}>
          <Stack spacing={1.5} sx={{ padding: 2 }}>
            {edittedAction.module && (
              <Typography variant="h6">
                {options.find((o) => o.module.name === edittedAction.module) &&
                  options.find((o) => o.module.name === edittedAction.module)
                    .module.notes}
              </Typography>
            )}
            {/* Module */}
            <FormControl required>
              <InputLabel id="edit-module">Module</InputLabel>
              <Select
                label="module"
                value={edittedAction.module}
                onChange={(e) =>
                  handleChangeEditModule(
                    e,
                    options,
                    edittedAction,
                    setEdittedAction,
                    setChangesMade
                  )
                }
              >
                {options.length > 0 &&
                  options
                    .sort((a, b) => a.module.name.localeCompare(b.module.name)) // Sort alphabetically
                    .map((option, index) => (
                      <MenuItem key={index} value={option.module.name}>
                        {option.module.name}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>

            {/* Note */}
            <FormControl>
              <TextField
                multiline
                style={{
                  height: "auto",
                  fontSize: "20px",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  width: "100%",
                }}
                label="Add Optional Note"
                value={edittedAction.note}
                onChange={(e) =>
                  handleEditNoteChange(e, setEdittedAction, setChangesMade)
                }
              ></TextField>
            </FormControl>

            <Stack direction="row" spacing={2} alignItems="center">
              {/* Clean Up */}
              <FormControlLabel
                sx={{ maxWidth: 170 }}
                label="Clean Up Step"
                labelPlacement="start"
                control={
                  <Switch
                    checked={edittedAction.clean_up}
                    onChange={(event) =>
                      setEdittedAction((prevData) => ({
                        ...prevData,
                        clean_up: event.target.checked,
                      }))
                    }
                    name="cleanUpToggle"
                  />
                }
              />
              {/* Action Time Out */}
              <FormControl>
                <TextField
                  type="number"
                  label="Action time out in seconds"
                  value={edittedAction.time_out}
                  onChange={(event) =>
                    setEdittedAction((prevData) => ({
                      ...prevData,
                      time_out: event.target.value,
                    }))
                  }
                  name="timeOutInput"
                  inputProps={{ min: "60" }} // Optional: Set minimum value
                />
              </FormControl>
            </Stack>

            {/* Arguments */}
            {edittedAction.module !== "padded_file" ? (
              <Stack
                style={{
                  marginBottom: 20,
                  border: "2px solid #d0d0d0",
                  borderRadius: "10px",
                  padding: "5px",
                }}
              >
                {edittedAction.arguments.length === 0 ? (
                  <Alert severity="info">No Current Arguments</Alert>
                ) : (
                  <>
                    {edittedAction.arguments.some((arg) =>
                      arg.startsWith("(")
                    ) && (
                      <Typography variant="h5">
                        Please fill in all missing inputs
                      </Typography>
                    )}
                    <Typography variant="h6" sx={{ marginBottom: 2 }}>
                      Current Arguments:
                    </Typography>

                    <Grid container spacing={2}>
                      {edittedAction.arguments.map((arg, index) => (
                        <React.Fragment key={index}>
                          {argEditIndex === index && (
                            <Grid item xs={20}>
                              <InputTypeSelector
                                data={data}
                                arg={arg}
                                argInputValue={argInputValue}
                                setArgInputValue={setArgInputValue}
                                argEditIndex={argEditIndex}
                                setArgEditIndex={setArgEditIndex}
                                setEdittedAction={setEdittedAction}
                                edittedAction={edittedAction}
                                setChangesMade={setChangesMade}
                                embedHandleChange={embedHandleChange}
                                isAddAction={false}
                                module={actionData.module}
                                setData={setData}
                                embedUniFile={embedUniFile}
                                uniFiles={uniFiles}
                                user={user}
                              />
                            </Grid>
                          )}

                          {index % 2 === 0 ? (
                            <Grid item xs={12} key={index}>
                              <Grid container spacing={1} alignItems="center">
                                <Grid item xs={5}>
                                  <Chip
                                    sx={{
                                      width: "50%",
                                      height: "auto",
                                      justifyContent: "space-between",
                                    }}
                                    variant="outlined"
                                    onClick={() =>
                                      handleArgChipClick(
                                        index,
                                        arg,
                                        setArgEditIndex,
                                        setArgInputValue
                                      )
                                    }
                                    onDelete={() =>
                                      handleEditArgdelete(
                                        index,
                                        setEdittedAction,
                                        setChangesMade
                                      )
                                    }
                                    label={arg}
                                  />
                                </Grid>
                                {index + 1 < edittedAction.arguments.length ? (
                                  <Grid item xs={7}>
                                    <Chip
                                      onClick={() =>
                                        handleArgChipClick(
                                          index + 1,
                                          edittedAction.arguments[index + 1],
                                          setArgEditIndex,
                                          setArgInputValue
                                        )
                                      }
                                      sx={{
                                        ...chipStylesArgs.chip,
                                        backgroundColor:
                                          edittedAction.arguments[
                                            index + 1
                                          ].startsWith("(Input")
                                            ? COLORS.error.light
                                            : COLORS.success.light, // Change "red" to your desired background color
                                      }}
                                      label={
                                        <div
                                          style={chipStylesArgs.labelContainer}
                                        >
                                          <ExpandableText
                                            text={
                                              edittedAction.arguments[index + 1]
                                            }
                                          />
                                        </div>
                                      }
                                    />
                                  </Grid>
                                ) : null}
                              </Grid>
                            </Grid>
                          ) : null}
                        </React.Fragment>
                      ))}
                    </Grid>
                  </>
                )}
                <Divider sx={{ marginTop: 2, marginBottom: 1 }} />
                {edittedAction.module &&
                options.length > 0 &&
                options.find((o) => o.module.name === edittedAction.module)
                  .module?.required_options ? (
                  <>
                    {!(
                      edittedAction.module === "run" &&
                      edittedAction.arguments.length > 0
                    ) && (
                      <>
                        <Typography>
                          Required Option for {edittedAction.module} module{" "}
                          {"(Please Select One)"}:{" "}
                        </Typography>
                        <Box sx={{ flexDirection: "column" }}>
                          {edittedAction.module &&
                            options
                              .find(
                                (o) => o.module.name === edittedAction.module
                              )
                              .module?.required_options.map((arg, index) => (
                                <div key={index}>
                                  <Select
                                    value={
                                      Array.from(arg).some(
                                        (arg) => arg === argInputValue
                                      )
                                        ? argInputValue
                                        : ""
                                    }
                                    onChange={(e) =>
                                      handleEditReqOptChange(
                                        e,
                                        setEdittedAction,
                                        setChangesMade
                                      )
                                    }
                                  >
                                    <InputLabel>Choose an option</InputLabel>
                                    {arg.map((arg2, index) => (
                                      <MenuItem key={index} value={arg2}>
                                        {arg2.arg} - {arg2.description}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </div>
                              ))}
                        </Box>
                        <Divider sx={{ marginTop: 1 }} />
                      </>
                    )}
                  </>
                ) : null}

                {edittedAction.module &&
                  options.length > 0 &&
                  options
                    .find((o) => o.module.name === edittedAction.module)
                    .module.args.filter((arg) => arg.required === "False")
                    .length > 0 && (
                    <>
                      <Typography>
                        Optional Add In Arguments {"(Click to Add)"}:{" "}
                      </Typography>
                      <Box sx={{ flexDirection: "column" }}>
                        {edittedAction.module &&
                          options
                            .find((o) => o.module.name === edittedAction.module)
                            .module.args.filter(
                              (arg) => arg.required === "False"
                            )
                            .map((arg, index) => (
                              <div key={index}>
                                <Typography variant="h5">
                                  {arg.arg}{" "}
                                  <Typography
                                    component={"span"}
                                    sx={{ size: 5 }}
                                  >
                                    {arg.description}{" "}
                                  </Typography>
                                  <Button
                                    variant="contained"
                                    onClick={() =>
                                      handleEditOptionalArgumentsClick(
                                        arg,
                                        setEdittedAction,
                                        setChangesMade
                                      )
                                    }
                                  >
                                    <FaPlus />
                                  </Button>
                                </Typography>
                              </div>
                            ))}
                      </Box>
                    </>
                  )}
              </Stack>
            ) : (
              <PaddedFileModule
                actionData={edittedAction}
                setActionData={setEdittedAction}
              />
            )}
            {/* TTPs */}
            <FormControl required>
              <Stack
                spacing={1}
                sx={{
                  border: "2px solid #d0d0d0",
                  borderRadius: "10px",
                  padding: "5px",
                }}
              >
                <Box>
                  <SelectSearch
                    className={classes}
                    multiple
                    search
                    placeholder="Search Techniques"
                    value={edittedAction.ttps}
                    onChange={(e) =>
                      handleEditTechniqueChangeAdd(
                        e,
                        setEdittedAction,
                        setChangesMade
                      )
                    }
                    options={techniques
                      .filter((tech) => {
                        return !edittedAction.ttps
                          .flat()
                          .includes(tech.attack_id);
                      })
                      .map((tech) => ({
                        name: `${tech.attack_id} - ${tech.name}`,
                        value: tech.attack_id,
                      }))}
                  />
                </Box>
                <Box>
                  <Typography sx={{ marginLeft: 1, marginTop: 1 }} variant="h6">
                    Selected TTPS
                  </Typography>
                  <Divider />
                  {/* Half of the container */}
                  <div>
                    {edittedAction.ttps.length === 0 ? (
                      <Alert severity="info">No TTPS Selected</Alert>
                    ) : (
                      <>
                        {edittedAction.ttps.map((techniqueId) => {
                          const selectedTechnique = techniques.find(
                            (tech) => tech.attack_id === techniqueId
                          );
                          return (
                            <Chip
                              key={techniqueId}
                              label={
                                <Box display="flex" alignItems="center">
                                  {selectedTechnique && (
                                    <Box
                                      sx={{
                                        flex: 1,
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      {selectedTechnique.name +
                                        "-" +
                                        selectedTechnique.attack_id}
                                    </Box>
                                  )}
                                </Box>
                              }
                              onDelete={() => {
                                handleEditTechniqueChangeRemove(
                                  edittedAction.ttps.filter(
                                    (id) => id !== techniqueId
                                  ),
                                  setEdittedAction,
                                  setChangesMade
                                );
                              }}
                              sx={{
                                height: "auto",
                                "& .MuiChip-label": {
                                  display: "block",
                                  whiteSpace: "normal",
                                },
                                marginTop: 1,
                                width: "100%",
                                minHeight: "30px", // Adjust this value as needed
                                justifyContent: "space-between",
                                textAlign: "left",
                                backgroundColor: "lightgray",
                              }}
                            />
                          );
                        })}
                      </>
                    )}
                  </div>
                </Box>
              </Stack>
            </FormControl>
            {/* Expected Outcome */}
            <FormControl required>
              <ThemeProvider theme={theme}>
                <ButtonGroup
                  variant="outlined"
                  aria-label="outlined button group"
                  size="large"
                  fullWidth
                >
                  <Tooltip title="Blocked" placement="top" arrow>
                    <Button
                      color="success"
                      variant={
                        edittedAction.expected_outcome === "blocked"
                          ? "contained"
                          : "outlined"
                      }
                      sx={{
                        backgroundColor:
                          edittedAction.expected_outcome === "blocked" &&
                          "success",
                      }}
                      onClick={() => {
                        setEdittedAction((data) => ({
                          ...data,
                          expected_outcome: "blocked",
                        }));
                      }}
                    >
                      <FaBan />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Alerted" placement="top" arrow>
                    <Button
                      color="primary"
                      variant={
                        edittedAction.expected_outcome === "alerted"
                          ? "contained"
                          : "outlined"
                      }
                      sx={{
                        backgroundColor:
                          edittedAction.expected_outcome === "alerted" &&
                          "primary",
                      }}
                      onClick={() => {
                        setEdittedAction((data) => ({
                          ...data,
                          expected_outcome: "alerted",
                        }));
                      }}
                    >
                      <FaExclamationTriangle />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Logged" placement="top" arrow>
                    <Button
                      color="logged"
                      variant={
                        edittedAction.expected_outcome === "logged"
                          ? "contained"
                          : "outlined"
                      }
                      sx={{
                        backgroundColor:
                          edittedAction.expected_outcome === "logged" &&
                          "logged",
                      }}
                      onClick={() => {
                        setEdittedAction((data) => ({
                          ...data,
                          expected_outcome: "logged",
                        }));
                      }}
                    >
                      <FaFileCode />
                    </Button>
                  </Tooltip>
                  <Tooltip title="No Evidence" placement="top" arrow>
                    <Button
                      color="error"
                      variant={
                        edittedAction.expected_outcome === "nothing"
                          ? "contained"
                          : "outlined"
                      }
                      sx={{
                        backgroundColor:
                          edittedAction.expected_outcome === "nothing" &&
                          "error",
                      }}
                      onClick={() => {
                        setEdittedAction((data) => ({
                          ...data,
                          expected_outcome: "nothing",
                        }));
                      }}
                    >
                      <FaEyeSlash />
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              </ThemeProvider>
            </FormControl>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default EditActionDrawer;
