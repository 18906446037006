import JSZip from "jszip";
import { toast } from "react-toastify";
import { getRequest, postRequest } from "../../../Helpers/httpRequests";

//function used to publish the sim
export const publishSim = async (
  selectedSim,
  accessToken,
  data,
  setSims,
  setPublishModal,
  setData,
  navigate,
  current_customer
) => {
  const loadingToast = toast.loading("Attempting to Publish Simulation");
  const res = await postRequest(
    `/react/api/${current_customer.uuid}/payload_builder/publish/${selectedSim.id}`,
    accessToken,
    data,
    false
  );
  if (res.status === 200) {
    toast.dismiss(loadingToast);
    toast.success(
      "Sim published Successfully! You are now being directed to set the thresholds for this Simulation"
    );
    navigate(`/${current_customer.uuid}/simulations/${selectedSim.id}`);
  } else {
    toast.dismiss(loadingToast);
    toast.error("Something went wrong!");
  }
};

//function used to duplicate sim
export const duplicateSim = async (
  sim_id,
  accessToken,
  current_customer,
  data,
  setReload
) => {
  const loadingToast = toast.loading("Attempting to Duplicate Sim");
  const res = await postRequest(
    `/react/api/${current_customer.uuid}/payload_builder/duplicate/${sim_id}`,
    accessToken,
    data,
    true
  );
  if (res.status === 200) {
    toast.dismiss(loadingToast);
    toast.success("Sim duplicated Successfully!");
    setReload((current) => !current);
  } else {
    toast.dismiss(loadingToast);
    toast.error("Something went wrong!");
  }
};

//function used to delete a Sim
export const deleteSim = async (
  sim,
  accessToken,
  current_customer,
  setSims,
  setDeleteModal
) => {
  const loadingToast = toast.loading(`Attempting to Delete Simulation`);
  const res = await postRequest(
    `/react/api/${current_customer.uuid}/payload_builder/delete/${sim}`,
    accessToken
  );
  if (res.status === 200) {
    toast.dismiss(loadingToast);
    toast.success(`Sim deleted Successfully!`);
    setSims((currentSims) => currentSims.filter((s) => s.id !== sim));
    setDeleteModal(false);
  } else {
    toast.dismiss(loadingToast);
    toast.error("Something went wrong!");
  }
};

//function used to handle when an argument chip is clicked into
export const handleArgChipClick = (
  index,
  arg,
  setArgEditIndex,
  setArgInputValue
) => {
  setArgEditIndex(index);
  setArgInputValue(arg);
};

// function used to handle clicking out of an argument
export const handleArgInputBlur = (
  argInputValue,
  argEditIndex,
  setActionData,
  actionData,
  setArgEditIndex,
  setArgInputValue
) => {
  if (argInputValue !== "") {
    let modified = false;
    let cmdmodified = false;
    if (
      actionData.arguments[argEditIndex - 1] === "powershell.exe" &&
      actionData.module === "run"
    ) {
      const lintedArgInputValue = lintString(argInputValue);
      if (lintedArgInputValue !== argInputValue) {
        // Linting resulted in a change
        argInputValue = lintedArgInputValue;
        modified = true;
      }
    }
    if (
      actionData.arguments[argEditIndex - 1] === "cmd.exe" &&
      actionData.module === "run"
    ) {
      const lintedArgInputValue = cmdLintString(argInputValue);
      if (lintedArgInputValue !== argInputValue) {
        argInputValue = lintedArgInputValue;
        cmdmodified = true;
      }
    }
    setActionData((data) => ({
      ...data,
      arguments: data.arguments.map((arg, i) =>
        i === argEditIndex ? argInputValue : arg
      ),
    }));
    setArgEditIndex(-1);
    setArgInputValue("");

    if (modified) {
      toast.info("Added escape characters", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1500,
      });
    }
    if (cmdmodified) {
      toast.info("added /c to command", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1500,
      });
    }
  }
};

//function used to handle when a user is finished editting an item or clicked out
export const handleEditArgInputBlur = (
  argInputValue,
  argEditIndex,
  setEdittedAction,
  edittedAction,
  setArgEditIndex,
  setArgInputValue
) => {
  let modified = false;
  let cmdmodified = false;
  if (
    edittedAction.arguments[argEditIndex - 1] === "powershell.exe" &&
    edittedAction.module === "run"
  ) {
    const lintedArgInputValue = lintString(argInputValue);
    if (lintedArgInputValue !== argInputValue) {
      argInputValue = lintedArgInputValue;
      modified = true;
    }
  }
  if (
    edittedAction.arguments[argEditIndex - 1] === "cmd.exe" &&
    edittedAction.module === "run"
  ) {
    const lintedArgInputValue = cmdLintString(argInputValue);
    if (lintedArgInputValue !== argInputValue) {
      argInputValue = lintedArgInputValue;
      cmdmodified = true;
    }
  }
  setEdittedAction((data) => ({
    ...data,
    arguments: data.arguments.map((arg, i) =>
      i === argEditIndex ? argInputValue : arg
    ),
  }));
  setArgEditIndex(-1);
  setArgInputValue("");
  if (modified) {
    toast.info("Added escape characters", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1500,
    });
  }
  if (cmdmodified) {
    toast.info("added /c to command", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1500,
    });
  }
};

//function used to upload a pre existing simnualtion json to either edit or test
export const handleUploadFileChange = (event, setUploadModal, setData) => {
  const file = event.target.files[0];
  const reader = new FileReader();
  reader.onload = (e) => {
    const jsonData = JSON.parse(e.target.result);
    const modifiedActions = jsonData.actions.map((action) => {
      return {
        module: action.module,
        ttps: action.tags,
        arguments: action.args,
      };
    });
    setData({
      simDesc: jsonData.desc,
      simName: jsonData.name,
      input_files: jsonData.files,
      actions: modifiedActions,
      endpoint: "",
      category: "",
      platform: "windows",
    });
  };
  reader.readAsText(file);
  setUploadModal(false);
};

//function used to get simulation info
export const setSimInfo = (sim_json, setData) => {
  const modifiedActions = sim_json.actions.map((action) => {
    return {
      module: action.module,
      ttps: action.tags,
      arguments: action.args,
      expected_outcome:
        (action.expected_outcome && action.expected_outcome) || null,
      note: action.note && action.note,
      clean_up: action.clean_up && action.clean_up,
      time_out: action.time_out && action.time_out,
    };
  });
  setData({
    simDesc: sim_json.desc,
    simName: sim_json.name,
    input_files: sim_json.files,
    actions: modifiedActions,
    endpoint: "",
    category: "",
    platform: "windows",
  });
};

//fucntion used for drag and drop
export const getItemStyle = (isDragging, draggableStyle) => ({
  hidden: isDragging ? true : false,
  ...draggableStyle,
});

//function used to set index at the end of drag and drop
export const onDragEnd = (result, data, setData) => {
  if (!result.destination) {
    return;
  }

  const newItems = Array.from(data.actions);
  const [removed] = newItems.splice(result.source.index, 1);
  newItems.splice(result.destination.index, 0, removed);

  setData((data) => ({
    ...data,
    actions: newItems,
  }));
};

// Function used to duplicate an action
export const duplicateAction = (index, data, setData) => {
  if (index >= 0 && index < data.actions.length) {
    const actionToDuplicate = { ...data.actions[index] };
    const updatedActions = [
      ...data.actions.slice(0, index + 1),
      actionToDuplicate,
      ...data.actions.slice(index + 1),
    ];
    setData({ ...data, actions: updatedActions });
  }
};

// function used to delete an action
export const deleteAction = (deleteUUID, data, setDeleteModal) => {
  let index = deleteUUID;
  if (index > -1) {
    data.actions.splice(index, 1);
    setDeleteModal(false);
  }
};

//fucntion used to edit an action
export const EditAction = (
  index,
  data,
  setEdittedAction,
  setInitialEdittedAction,
  setEditPage,
  setEdittedActionIndex
) => {
  setEdittedActionIndex(index);
  setEdittedAction(data.actions[index]);
  setInitialEdittedAction(data.actions[index]);
  setEditPage(true);
};

// fucntion used to save a built sim as a draft
export const saveSim = async (
  data,
  accessToken,
  setData,
  setReloadPage,
  navigate,
  setChangesMade,
  setConflict,
  setConflictBy,
  setConflictTime,
  current_customer,
  campaignLevelVariables
) => {
  const zip = new JSZip();
  const commentsArray = []; // Create an array to store comments
  if (data.input_files && Array.isArray(data.input_files)) {
    for (const file of data.input_files) {
      const fileBlob = new Blob([file.content]);
      const metadata = {
        uploaded_by: file.uploaded_by,
        uploaded_date: file.uploaded_date,
      };
      const metadataJson = JSON.stringify(metadata);
      commentsArray.push(metadataJson);
      zip.file(file.name, fileBlob);
    }
  }
  const commentsJson = JSON.stringify(commentsArray);
  zip.comment = commentsJson;

  const zipContent = await zip.generateAsync({ type: "base64" });

  const requestData = {
    simName: data.simName,
    simDesc: data.simDesc,
    actions: JSON.stringify(data.actions),
    category: data.category,
    platform: data.platform,
    input_files_zip: zipContent,
    campaignLevelVariables: campaignLevelVariables,
  };

  const loadingToast = toast.loading("Attempting to save draft simulation");
  const res = await postRequest(
    `/react/api/${current_customer}/payload_builder/save`,
    accessToken,
    requestData,
    false
  );

  if (res.status === 200) {
    toast.dismiss(loadingToast);
    toast.success(`'${data.simName}' saved Successfully!`);
    setChangesMade(false);
    setConflict(false);
    navigate(`/${current_customer.uuid}/beta/buildNewPayload/${res.data.uuid}`);
  } else if (res.status === 400) {
    toast.dismiss(loadingToast);
    toast.error(
      "Error: A Simulation with the same name already exists. Please change the name."
    );
  } else if (res.status === 409) {
    toast.dismiss(loadingToast);
    setConflictBy(res.data.user);
    setConflictTime(res.data.time);
    setConflict(true);
  } else {
    toast.dismiss(loadingToast);
    toast.error("Something went wrong!");
  }
};

//function used to test a sim that was built
export const testSim = async (
  data,
  campaignLevelVariables,
  accessToken,
  current_customer,
  setTesting,
  setTestingResults,
  setPollInterval
) => {
  setTestingResults([]);
  const zip = new JSZip();
  if (data.input_files && Array.isArray(data.input_files)) {
    for (const file of data.input_files) {
      const fileBlob = new Blob([file.content]); // Create a Blob from the content
      zip.file(file.name, fileBlob);
    }
  }
  const zipContent = await zip.generateAsync({ type: "base64" });

  const requestData = {
    simName: data.simName,
    simDesc: data.simDesc,
    actions: JSON.stringify(data.actions),
    category: data.category,
    platform: data.platform,
    endpoint: data.endpoint,
    input_files_zip: zipContent,
    execMethod: data.execMethod,
    piMethod: data.piMethod,
    piTarget: data.piTarget,
    sleepTime: data.sleepTime,
    maxRuntime: data.maxRuntime,
    transform: data.transform,
    architecture: data.architecture,
    inMemory: data.inMemory,
    obfuscate: data.obfuscate,
    campaignLevelVariables: campaignLevelVariables,
    testValues: data.testValues,
  };
  const infoToast = toast.info("Testing Simulation");
  try {
    const res = await postRequest(
      `/react/api/${current_customer.uuid}/payload_builder/test`,
      accessToken,
      requestData,
      false
    );
    if (res.status === 200) {
      toast.dismiss(infoToast);
      toast.success(`'${data.simName}' Sim created and being tested`);
      setTesting(true);
      const pollInterval = setInterval(async () => {
        const pollRes = await getRequest(
          `/react/api/${current_customer.uuid}/payload_builder/results/${res.data.payload_uuid}`,
          accessToken
        );
        if (pollRes.status === 200) {
          setTestingResults(pollRes.data.results);
        }
        if (pollRes.data.complete === true) {
          clearInterval(pollInterval);
          // Handle the completion of the simulation testing
          toast.success("Simulation Test Complete");
          setTesting(false);
        }
      }, 5000); // Poll every 5 seconds
      setPollInterval(pollInterval);
    } else {
      toast.dismiss(infoToast);
      toast.error("Something went wrong!");
    }
  } catch (error) {
    toast.dismiss(infoToast);
    toast.error("Failed to connect to server");
  }
};

// function to capitalize word
export const capitalizeWord = (word) => {
  return word.replace(/\b\w/g, (match) => match.toUpperCase());
};

export const lintString = (string) => {
  const unescapedQuotes = /(?<!\\)"/g;
  string = string.replace(unescapedQuotes, '\\"');
  return string;
};

export const cmdLintString = (string) => {
  if (!string.startsWith("/c ")) {
    string = "/c " + string;
  }
  return string;
};

//function used to change the execution method
export function handleExecMethodChange(e, setData) {
  const value = e.target.value;

  switch (value) {
    case "local":
      setData((prevData) => ({
        ...prevData,
        execMethod: value,
        piMethod: "",
        piTarget: "",
        inMemory: false,
      }));
      break;
    case "sh":
      setData((prevData) => ({
        ...prevData,
        execMethod: value,
        transform: "",
        inMemory: false,
      }));
      break;
    default:
      setData((prevData) => ({
        ...prevData,
        execMethod: value,
        piMethod: "",
        piTarget: "",
        transform: "",
        inMemory: false,
      }));
      break;
  }
}

//function to used to get the remaining actions
export const getRemainingActionsCount = (data) => {
  const remaining = 40 - data.actions.length;
  if (remaining < 0) {
    return "Exceeded Capacity";
  } else {
    return remaining;
  }
};

//Function to add technique
export const handleTechniqueChangeAdd = (
  newValues,
  setActionData,
  setChangesMade
) => {
  // Flatten the nested array and concatenate it with the existing ttps array
  const flattenedNewValues = newValues.flat();
  setActionData((data) => ({
    ...data,
    ttps: [...data.ttps, ...flattenedNewValues], // Append new values to existing ones
  }));
  setChangesMade(true);
};

//Function to remove technique
export const handleTechniqueChangeRemove = (
  newValues,
  setActionData,
  setChangesMade
) => {
  setActionData((data) => ({
    ...data,
    ttps: newValues,
  }));
  setChangesMade(true);
};

//Function to remove technique for edit
export const handleEditTechniqueChangeRemove = (
  newValues,
  setEdittedAction,
  setChangesMade
) => {
  setEdittedAction((data) => ({
    ...data,
    ttps: newValues,
  }));
  setChangesMade(true);
};

//Function to add technique for edit
export const handleEditTechniqueChangeAdd = (
  newValues,
  setEdittedAction,
  setChangesMade
) => {
  // Flatten the nested array and concatenate it with the existing ttps array
  const flattenedNewValues = newValues.flat();
  setEdittedAction((data) => ({
    ...data,
    ttps: [...data.ttps, ...flattenedNewValues], // Append new values to existing ones
  }));
  setChangesMade(true);
};

//Function to close Editted Action
export const handleEdittedActionClose = (setEdittedActionModal) => {
  setEdittedActionModal(false);
};

//Function to close add Action
export const handleAddActionClose = (setAddActionModal) => {
  setAddActionModal(false);
};

// The function below handles the deletion of a file from the input_files array.
export const handleDeleteFile = (index, setData, setChangesMade) => {
  setData((prevData) => {
    const updatedFiles = [...prevData.input_files];
    updatedFiles.splice(index, 1);
    return {
      ...prevData,
      input_files: updatedFiles,
    };
  });
  setChangesMade(true);
};

export const handleFileInputChange = (e, setChangesMade, setData, user) => {
  setChangesMade(true);
  const selectedFile = e.target.files[0];

  const reader = new FileReader();
  reader.onload = () => {
    let content = reader.result.split(",")[1]; // Get the base64 part of the data URL
    // Imported file with base64 content and name
    const byteCharacters = atob(content); // Decode base64
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    // Calculate the size in KB from the byteArray
    const sizeKB = (byteArray.length / 1024).toFixed(2);
    setData((prevData) => ({
      ...prevData,
      input_files: [
        ...prevData.input_files,
        {
          name: selectedFile.name,
          content: byteArray,
          size: sizeKB,
          uploaded_by: user.user.email,
          uploaded_date: new Date().toLocaleString(),
        },
      ],
    }));
  };
  reader.readAsDataURL(selectedFile);
};

// The function below is used to download a file in the input files array
export const handleDownloadFile = (file) => {
  if (file instanceof File) {
    // Uploaded file from computer
    const url = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    link.download = file.name;
    link.click();
  } else if (file.content && file.name) {
    // Imported file with content and name
    const blob = new Blob([file.content], {
      type: "application/octet-stream",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = file.name;
    link.click();
  }
};

// function used to change the execution method
export const handleChange = (
  e,
  handleExecMethodChange,
  setData,
  setChangesMade
) => {
  handleExecMethodChange(e, setData);
  setChangesMade(true);
};

//function used to add the embbed action with the file name
export const embedHandleChange = (
  e,
  argEditIndex,
  isAddAction,
  module,
  setActionData,
  setEdittedAction,
  setArgInputValue,
  setChangesMade,
  actionData,
  edittedAction
) => {
  const selectedValue =
    module === "embed" ? "embed://" + e.target.value : e.target.value;
  if (isAddAction) {
    const updatedActionData = {
      ...actionData,
      arguments: actionData.arguments.map((a, i) =>
        i === argEditIndex ? selectedValue : a
      ),
    };
    setActionData(updatedActionData);
  } else {
    const updatedActionData = {
      ...edittedAction,
      arguments: edittedAction.arguments.map((a, i) =>
        i === argEditIndex ? selectedValue : a
      ),
    };
    setEdittedAction(updatedActionData);
  }

  setArgInputValue(selectedValue);
  setChangesMade(true);
};

//function used to add the embbed action with the file name
export const embedUniFile = (
  e,
  argEditIndex,
  isAddAction,
  module,
  setActionData,
  setEdittedAction,
  setArgInputValue,
  setChangesMade,
  actionData,
  edittedAction,
  setData,
  user
) => {
  const file = e.target.value;
  setData((prevData) => ({
    ...prevData,
    input_files: [
      ...prevData.input_files,
      {
        name: file.name,
        content: file.content,
        size: file.size,
        uploaded_by: user.user.email,
        uploaded_date: new Date().toLocaleString(),
        alias: "",
      },
    ],
  }));
  const selectedValue =
    module === "embed" ? "embed://" + e.target.value.name : e.target.value.name;
  if (isAddAction) {
    const updatedActionData = {
      ...actionData,
      arguments: actionData.arguments.map((a, i) =>
        i === argEditIndex ? selectedValue : a
      ),
    };
    setActionData(updatedActionData);
  } else {
    const updatedActionData = {
      ...edittedAction,
      arguments: edittedAction.arguments.map((a, i) =>
        i === argEditIndex ? selectedValue : a
      ),
    };
    setEdittedAction(updatedActionData);
  }

  setArgInputValue(selectedValue);
  setChangesMade(true);
};

// function used to add a required options for add action
export const handleReqOptChange = (e, setActionData, setChangesMade) => {
  if (e.target.value.arg !== "(Custom Line)") {
    setActionData((data) => ({
      ...data,
      arguments: [
        ...data.arguments,
        e.target.value.arg,
        (() => {
          if (e.target.value.input_type === "bool") {
            return "";
          } else {
            return (
              "(Input " +
              e.target.value.input_type +
              " : " +
              e.target.value.description +
              ")"
            );
          }
        })(),
      ],
    }));
  } else {
    setActionData((data) => ({
      ...data,
      arguments: [...data.arguments, e.target.value.arg, ""],
    }));
  }
  setChangesMade(true);
};

// function used to add a required options for edit action
export const handleEditReqOptChange = (e, setEdittedAction, setChangesMade) => {
  if (e.target.value.arg !== "(Custom Line)") {
    setEdittedAction((data) => ({
      ...data,
      arguments: [
        ...data.arguments,
        e.target.value.arg,
        (() => {
          if (e.target.value.input_type === "bool") {
            return "";
          } else {
            return (
              "(Input " +
              e.target.value.input_type +
              " : " +
              e.target.value.description +
              ")"
            );
          }
        })(),
      ],
    }));
  } else {
    setEdittedAction((data) => ({
      ...data,
      arguments: [...data.arguments, e.target.value.arg, ""],
    }));
  }
  setChangesMade(true);
};

//functions used to add an optional argument for an add action
export const handleOptionalArgumentsClick = (
  arg,
  setActionData,
  setChangesMade
) => {
  setActionData((data) => {
    const newArgument = arg.arg;
    let newValue;

    if (arg && arg.default !== undefined) {
      newValue = arg.default;
    } else if (arg && arg.input_type === "bool") {
      newValue = "";
    } else {
      newValue = `(Input ${arg.input_type} : ${arg.description})`;
    }

    return {
      ...data,
      arguments: [...data.arguments, newArgument, newValue],
    };
  });
  setChangesMade(true);
};

//functions used to add an optional argument for an add action
export const handleEditOptionalArgumentsClick = (
  arg,
  setEdittedAction,
  setChangesMade
) => {
  setEdittedAction((data) => {
    const newArgument = arg.arg;
    let newValue;

    if (arg && arg.default !== undefined) {
      newValue = arg.default;
    } else if (arg && arg.input_type === "bool") {
      newValue = "";
    } else {
      newValue = `(Input ${arg.input_type} : ${arg.description})`;
    }

    return {
      ...data,
      arguments: [...data.arguments, newArgument, newValue],
    };
  });
  setChangesMade(true);
};

// function used to change module in add action
export const handleModuleChange = (e, options, setActionData, actionData) => {
  const selectedModule = options.find(
    (o) => o.module.name === e.target.value
  ).module;

  if (selectedModule) {
    const updatedActionData = {
      ...actionData,
      module: e.target.value,
      ttps: selectedModule.ttps.flatMap((valueArray) => valueArray),
      arguments: selectedModule.args
        .filter((arg) => arg.required === "True")
        .flatMap((arg) => [
          arg.arg,
          arg.input_type !== "bool"
            ? "(Input " + arg.input_type + " : " + arg.description + ")"
            : "",
        ]),
    };

    setActionData(updatedActionData);
  }
};

// function used to change module in add action
export const handleNoteChange = (e, setActionData, setChangesMade) => {
  setActionData((data) => ({
    ...data,
    note: e.target.value,
  }));
  setChangesMade(true);
};

// function used to change module in add action
export const handleEditNoteChange = (e, setEdittedAction, setChangesMade) => {
  setEdittedAction((data) => ({
    ...data,
    note: e.target.value,
  }));
  setChangesMade(true);
};

// function used to add an action
export const handleAddActionClick = (
  testingResults,
  data,
  setTestHistory,
  setData,
  addActionIndex,
  setAddActionIndex,
  setActionData,
  setChangesMade,
  actionData
) => {
  if (testingResults.length > 0) {
    // Entry for previous test history
    const historyEntry = [data, testingResults, new Date().toLocaleString()];
    setTestHistory([historyEntry]);
  }

  if (actionData.module === "crypt") {
    const moveArgumentToPosition = (argument, position) => {
      const index = actionData.arguments.indexOf(argument);
      if (index !== -1 && index < actionData.arguments.length - 1) {
        const value = actionData.arguments.splice(index + 1, 1)[0];
        actionData.arguments.splice(index, 1);
        actionData.arguments.splice(position, 0, argument, value);
      }
    };

    moveArgumentToPosition("--target", 0);
    moveArgumentToPosition("--password", 2);
    moveArgumentToPosition("--encrypt", 4);
    moveArgumentToPosition("--decrypt", 4);
  }

  setData((prevData) => {
    const newActions = [...prevData.actions];
    newActions.splice(addActionIndex, 0, actionData);

    return {
      ...prevData,
      actions: newActions,
    };
  });
  setAddActionIndex(addActionIndex + 1);
  setActionData({
    module: "run",
    ttps: ["T1059"],
    arguments: [],
    note: "",
    time_out: 60,
    expected_outcome: null,
  });
  setChangesMade(true);
};

// function used to change the module of an action being editted
export const handleChangeEditModule = (
  e,
  options,
  edittedAction,
  setEdittedAction,
  setChangesMade
) => {
  const selectedModule = options.find(
    (o) => o.module.name === e.target.value
  ).module;

  if (selectedModule) {
    const updatedEdittedAction = {
      ...edittedAction,
      module: e.target.value,
      ttps: selectedModule.ttps.flatMap((valueArray) => valueArray),
      arguments: selectedModule.args
        .filter((arg) => arg.required === "True")
        .flatMap((arg) => [
          arg.arg,
          arg.input_type !== "bool"
            ? "(Input " + arg.input_type + " : " + arg.description + ")"
            : "",
        ]),
    };

    setEdittedAction(updatedEdittedAction);
  }
  setChangesMade(true);
};

// function used to delete an editted argument
export const handleEditArgdelete = (
  index,
  setEdittedAction,
  setChangesMade
) => {
  setEdittedAction((data) => ({
    ...data,
    arguments: data.arguments.filter((a, i) => i !== index + 1 && i !== index),
  }));
  setChangesMade(true);
};

// function used to delete an editted argument
export const handleArgdelete = (index, setActionData, setChangesMade) => {
  setActionData((data) => ({
    ...data,
    arguments: data.arguments.filter((a, i) => i !== index + 1 && i !== index),
  }));
  setChangesMade(true);
};

// function used to edit an action
export const editAction = async (
  testingResults,
  data,
  setTestHistory,
  setEditPage,
  setData,
  setActionData,
  setChangesMade,
  edittedAction,
  edittedActionIndex
) => {
  if (testingResults.length > 0) {
    // Entry for previous test history
    const historyEntry = [data, testingResults, new Date().toLocaleString()];
    setTestHistory([historyEntry]);
  }
  if (edittedActionIndex.module === "crypt") {
    const moveArgumentToPosition = (argument, position) => {
      const index = edittedActionIndex.arguments.indexOf(argument);
      if (index !== -1 && index < edittedActionIndex.arguments.length - 1) {
        const value = edittedActionIndex.arguments.splice(index + 1, 1)[0];
        edittedActionIndex.arguments.splice(index, 1);
        edittedActionIndex.arguments.splice(position, 0, argument, value);
      }
    };

    moveArgumentToPosition("--target", 0);
    moveArgumentToPosition("--password", 2);
    moveArgumentToPosition("--encrypt", 4);
    moveArgumentToPosition("--decrypt", 4);
  }

  await Promise.resolve(); // Wait for the next event loop iteration
  setEditPage(false);
  setData((data) => ({
    ...data,
    actions: data.actions.map((action, index) => {
      if (index === edittedAction) {
        return edittedActionIndex;
      }
      return action;
    }),
  }));
  setActionData((data) => ({
    ...data,
    module: "run",
    ttps: ["T1059"],
    arguments: [],
    note: "",
    expected_outcome: null,
  }));
  setChangesMade(true);
};
