import { Stack, Breadcrumbs, Typography, CircularProgress } from "@mui/material";
import { TextButton } from "../../../../Components";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { FaFileCsv } from "react-icons/fa";
import { useContext, useState } from "react";
import { AvOverviewContext } from "../Provider/Context";
import { fileRequest } from "../../../../Helpers/httpRequests";
import { DataContext } from "../../../../Context/dataContext";
import { toast } from "react-toastify";

const BSBreadcrumbs = () => {
  const role = useAppSelector((state) => state.user.role);
  const current_customer = useAppSelector((state) => state.customer);
  const { startDate, endDate } = useContext(AvOverviewContext);
  const { accessToken } = useContext(DataContext);
  const [resLoading, setResLoading] = useState(false)

  const handleClick = async () => {
    setResLoading(true)
    toast.info("Generating CSV. This may take a while..")
    const res = await fileRequest(
      `/react/api/${current_customer.uuid}/alertvalidation/overview/get-csv`,
      accessToken,
      "Overview.csv",
      {
        startDate,
        endDate
      })
    if (res.status === 200) {
      setResLoading(false)
    } else {
      toast.error("Something went wrong")
      setResLoading(false)
    }
  }
  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.primary">Alert Validation</Typography>
          <Typography color="text.primary">Overview</Typography>
        </Breadcrumbs>
        <TextButton
          role={role}
          tooltip="Download CSV"
          icon={resLoading ? CircularProgress : FaFileCsv}
          onClick={handleClick}
        />

      </Stack>

    </>
  );
};

export default BSBreadcrumbs;
