import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import DataGridWithStyles from "../../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import CampaignContext from "../provider/CampaignContext";

import { useHttpRequest } from "../../../../Hooks";

const ActionsPermissionDenied = () => {
  const [updateID, setUpdateID] = useState(null);
  const [reason, setReason] = useState("not set");
  const [permissionDenied, setPermissionDenied] = useState(true);
  const [rows, setRows] = useState([]);

  const { options, findings, getFindings } = useContext(CampaignContext);
  const { fetch: submit, loading } = useHttpRequest({
    method: "PATCH",
    path: `/api/v2/cached-findings/${updateID}`,
    data: {
      permission_denied_reason: reason,
      permission_denied: permissionDenied,
    },
    defer: true,
  });

  const columns = [
    {
      field: "action",
      headerName: "Action",
      flex: 1,
    },
    {
      field: "reason",
      headerName: "Reason",
      flex: 1,
      renderCell: (params) => {
        return (
          <FormControl sx={{ width: "100%" }}>
            <Select
              labelId="reason"
              id="permission_denied_reason"
              value={params.row.permission_denied_reason}
              label="Reason"
              onChange={(e) => {
                // change the reason to the selected reason
                // const newFindings = findings.map((finding) => {
                //   if (finding.id === params.row.id) {
                //     return {
                //       ...finding,
                //       reason: e.target.value,
                //     };
                //   }
                //   return finding;
                // });
                setUpdateID(params.row.id);
                setReason(e.target.value);
                submit();
                getFindings();
              }}
            >
              {options?.permission_denied?.map((reason) => (
                <MenuItem key={reason[0]} value={reason[0]}>
                  {reason[1]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      renderCell: (params) => {
        // button to mark the action as not a permission denied action
        return (
          <Stack direction="row" spacing={1}>
            <ButtonGroup>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  // change the reason to the selected reason
                  // const newFindings = findings.map((finding) => {
                  //   if (finding.id === params.row.id) {
                  //     return {
                  //       ...finding,
                  //       permission_denied: false,
                  //       reason: "not set",
                  //     };
                  //   }
                  //   return finding;
                  // });
                  // setUpdateID(params.row.id);
                  setPermissionDenied(false);

                  submit();
                  getFindings();
                }}
              >
                Mark as False Positive
              </Button>
            </ButtonGroup>
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    if (findings !== null) {
      setRows(
        findings
          .filter((finding) => finding.permission_denied)
          .map((finding) => {
            return {
              id: finding.id,
              action: finding.name,
              permission_denied_reason: finding.permission_denied_reason,
            };
          })
      );
    }
  }, [findings]);

  useEffect(() => {
    if (loading === false) {
      setReason("not set");
      setPermissionDenied(true);
      setUpdateID(null);
    }
  }, [loading]);

  return rows.length > 0 ? (
    <Box sx={{ marginTop: 2 }}>
      <Paper>
        <Box sx={{ padding: 2 }}>
          <Typography variant="h6">Actions Permission Denied</Typography>
          <Box sx={{ height: 400, width: "100%" }}>
            <DataGridWithStyles
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  ) : (
    <></>
  );
};

export default ActionsPermissionDenied;
