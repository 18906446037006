import {
  Avatar,
  Box,
  Chip,
  Paper,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import { useContext, useEffect, useState } from "react";
import { FaDesktop, FaWindows } from "react-icons/fa";
import { Link } from "react-router-dom";
import { DataContext } from "../../../../Context/dataContext";
import { encryptId } from "../../../../Helpers/functions/uni_funcs";
import { getRequest } from "../../../../Helpers/httpRequests";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { COLORS } from "../../../../Styles/colors";
import { AvOverviewContext } from "../Provider/Context";
import { useHttpRequest } from "../../../../Hooks";

const Endpoints = () => {
  const { accessToken } = useContext(DataContext);
  const { data } = useContext(AvOverviewContext);
  const [scheduledExercises, setScheduledExercises] = useState([]);
  const current_customer = useAppSelector((state) => state.customer);

  useEffect(() => {
    const getAlerts = async () => {
      const res = await getRequest(
        `/api/v2/${current_customer.uuid}/scheduledexercises?depth=1`,
        accessToken
      );
      setScheduledExercises(res.data);
    };
    getAlerts();
  }, [data, accessToken, current_customer]);

  return (
    <Paper sx={{ p: 3, width: "50%" }}>
      <Stack spacing={2}>
        <Typography variant="h5">Endpoints with Scheduled Exercises</Typography>
        {scheduledExercises.map((se, idx) => {
          if (se.active) {
            return (
              <EndpointCard
                key={idx}
                scheduledExercise={se}
                current_customer={current_customer}
              />
            );
          }
          return null;
        })}
      </Stack>
    </Paper>
  );
};

const vendorImages = {
  Blackberry: require("../../../../Images/security_tools_icons/Blackberry.png"),
  "Carbon Black": require("../../../../Images/security_tools_icons/carbon_black.jpg"),
  CheckPoint: require("../../../../Images/security_tools_icons/checkpoint.png"),
  Cisco: require("../../../../Images/security_tools_icons/cisco.png"),
  Crowdstrike: require("../../../../Images/security_tools_icons/crowdstrike.png"),
  Elastic: require("../../../../Images/security_tools_icons/elastic.png"),
  McAfee: require("../../../../Images/security_tools_icons/mcafee.png"),
  Microsoft: require("../../../../Images/security_tools_icons/microsoft.jpg"),
  "Palo Alto Networks": require("../../../../Images/security_tools_icons/palo_alto.png"),
  SentinelOne: require("../../../../Images/security_tools_icons/sentinel_one.png"),
  Tanium: require("../../../../Images/security_tools_icons/tanium.png"),
  "Security Onion": require("../../../../Images/security_tools_icons/security_onion.png"),
  Sophos: require("../../../../Images/security_tools_icons/sophos.png"),
  Symantec: require("../../../../Images/security_tools_icons/symantec.png"),
  Varonis: require("../../../../Images/security_tools_icons/varonis.png"),
  WebSense: require("../../../../Images/security_tools_icons/websense.png"),
  ServiceNow: require("../../../../Images/security_tools_icons/service_now.png"),
  Fortinet: require("../../../../Images/security_tools_icons/fortinet.png"),
  Rapid7: require("../../../../Images/security_tools_icons/rapid7.png"),
  VMWare: require("../../../../Images/security_tools_icons/vmware.png"),
  DeepSeas: require("../../../../Images/security_tools_icons/deepseas.jpg"),
  Datto: require("../../../../Images/security_tools_icons/datto.jpg"),
  defender: require("../../../../Images/security_tools_icons/Windows-defender.png"),
  sentinel: require("../../../../Images/security_tools_icons/sentinel.png"),
  default: require("../../../../Images/security_tools_icons/default.png"),
  // Add more vendors and their image paths here
};

const EndpointCard = ({ scheduledExercise, current_customer }) => {
  const { startDate, endDate } = useContext(AvOverviewContext);
  const [info, setInfo] = useState({});

  const { response: res, fetch: passFetch } = useHttpRequest({
    method: "GET",
    path: `/react/api/${current_customer.uuid}/alertvalidation/overview/passrate`,
    params: {
      single: true,
      uuid: scheduledExercise.uuid,
      start: startDate.toISOString(),
      end: endDate.toISOString(),
    },
    defer: true,
  });

  const { response: mttdRes, fetch: mttdPassFetch } = useHttpRequest({
    method: "GET",
    path: `/react/api/${current_customer.uuid}/alertvalidation/overview/endpoint-mttd`,
    params: {
      endpoint_uuid: scheduledExercise.endpoint.uuid,
      start: startDate.toISOString(),
      end: endDate.toISOString(),
    },
    defer: true,
  });

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}m ${remainingSeconds}s`;
  };

  useEffect(() => {
    if (res === null) {
      passFetch();
    } else {
      setInfo({ ...info, ...res.passrate[0] });
    }
    // eslint-disable-next-line
  }, [res]);

  useEffect(() => {
    if (mttdRes === null) {
      mttdPassFetch();
    } else {
      setInfo({ ...info, ...mttdRes });
    }
    // eslint-disable-next-line
  }, [mttdRes]);

  const TIME_CHOICES = {
    0: "12am (Midnight)",
    1: "1am",
    2: "2am",
    3: "3am",
    4: "4am",
    5: "5am",
    6: "6am",
    7: "7am",
    8: "8am",
    9: "9am",
    10: "10am",
    11: "11am",
    12: "12pm (Noon)",
    13: "1pm",
    14: "2pm",
    15: "3pm",
    16: "4pm",
    17: "5pm",
    18: "6pm",
    19: "7pm",
    20: "8pm",
    21: "9pm",
    22: "10pm",
    23: "11pm",
  };
  return info ? (
    <Stack
      className="shadow"
      sx={{
        border: `1px solid ${COLORS.info.light}`,
        borderRadius: "4px",
        overflow: "hidden",
        boxShadow:
          "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          backgroundColor: `rgba(46,169,244,0.25)`,
          borderBottom: `1px solid ${COLORS.info.light}`,
          p: 1,
        }}
      >
        <Link
          to={`/${current_customer.uuid}/simulations/endpoints/detail/${scheduledExercise.endpoint.uuid}`}
        >
          <Typography variant="h6" flex={1}>
            {scheduledExercise.endpoint.host}
          </Typography>
        </Link>
        <Stack direction="row" spacing={1} alignItems="center">
          <FaWindows size={20} color={`${COLORS.primary.main}`} />
          <FaDesktop size={20} color={`${COLORS.primary.main}`} />
        </Stack>
      </Stack>
      <Stack spacing={1} p={1}>
        <Stack direction="row" spacing={1}>
          {scheduledExercise.security_stack.tools.map((t) => {
            const tool = t.tool;
            return (
              <Link
                key={t.uuid}
                to={`/${current_customer.uuid}/alertvalidation/config`}
              >
                <Chip
                  avatar={
                    <Avatar
                      src={
                        tool?.product?.includes("Defender")
                          ? vendorImages.defender
                          : tool?.product?.includes("SentinelOne")
                            ? vendorImages.SentinelOne
                            : tool?.product?.includes("Sentinel")
                              ? vendorImages.sentinel
                              : vendorImages[tool.vendor] || vendorImages.default
                      }
                      sx={{
                        backgroundColor: "transparent",
                      }}
                    />
                  }
                  label={t.tool.name}
                  sx={{ fontStyle: { color: COLORS.primary.main } }}
                />
              </Link>
            );
          })}
        </Stack>
      </Stack>
      <Stack
        direction="row"
        sx={{
          borderTop: `1px solid ${COLORS.info.light}`,
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRight: `1px solid ${COLORS.info.light}`,
            py: 1,
          }}
        >
          <Typography color="primary" variant="h6">
            Pass / Fail
          </Typography>
          {info.passed !== undefined ? (
            <PieChart
              series={[
                {
                  arcLabel: (item) => `${item.percent}%`,
                  highlightScope: { faded: "global", highlighted: "item" },
                  faded: {
                    innerRadius: 30,
                    additionalRadius: -30,
                    color: "gray",
                  },
                  data: [
                    {
                      id: 0,
                      value: info.passed,
                      label: "Passed",
                      percent: (
                        (info.passed / (info.passed + info.failed)) *
                        100
                      ).toFixed(2),
                    },
                    {
                      id: 1,
                      value: info.failed,
                      label: "Failed",
                      percent: (
                        (info.failed / (info.passed + info.failed)) *
                        100
                      ).toFixed(2),
                    },
                  ],
                },
              ]}
              height={150}
              margin={{ right: 5 }}
              colors={[COLORS.success.light, COLORS.error.light]}
              slotProps={{
                legend: { hidden: true },
              }}
            />
          ) : (
            <CircularProgress />
          )}
        </Box>
        <Stack
          flex={1}
          alignItems="center"
          sx={{
            borderRight: `1px solid ${COLORS.info.light}`,
            position: "relative",
          }}
        >
          <Link
            to={`/${current_customer.uuid}/alertvalidation/alerts/history/${encryptId(
              scheduledExercise.id
            )}`}
            style={{ position: "absolute", top: 4 }}
          >
            <Typography color="primary" variant="h6">
              {scheduledExercise.name}
            </Typography>
          </Link>
          <Box
            sx={{
              display: "flex",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Paper
              sx={{
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
              elevation={0}
            >
              <Typography variant="h4" color="primary">
                {TIME_CHOICES[scheduledExercise.time]}
              </Typography>
              <Typography
                variant="body2"
                color={COLORS.primary.main}
                className="capitalize"
              >
                {scheduledExercise.frequency}
              </Typography>
            </Paper>
          </Box>
        </Stack>
        <Stack
          flex={1}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            borderRight: `1px solid ${COLORS.info.light}`,
          }}
        >
          {info?.totalRuns === undefined ? (
            <CircularProgress />
          ) : (
            <Typography variant="h4" color={COLORS.primary.main}>
              {info?.totalRuns}
            </Typography>
          )}
          <Typography variant="body2" color={COLORS.primary.main}>
            Total runs
          </Typography>
        </Stack>
        <Stack
          flex={1}
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {info?.mttd === undefined ? (
            <CircularProgress />
          ) : (
            <Typography variant="h4" color={COLORS.primary.main}>
              {formatTime(info?.mttd)}
            </Typography>
          )}
          <Typography variant="body2" color={COLORS.primary.main}>
            Mean Time to Detect
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  ) : null;
};

export default Endpoints;
