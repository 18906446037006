import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminPayloadBuilder from "../../Pages/Administration/AdminPayloadBuilder/AdminPayloadBuilder";
import BuildNewPayload from "../../Pages/Administration/AdminPayloadBuilder/BuildNewPayload";


const BetaRoutes = () => {
  return (
    <Routes>
      <Route path="payload-builder" element={<AdminPayloadBuilder/>}/>
      <Route path="buildnewpayload/:sim_uuid" element={<BuildNewPayload/>}/>
      <Route path="buildnewpayload" element={<BuildNewPayload/>}/>
    </Routes>
  );
};

export default BetaRoutes;
