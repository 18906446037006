import React from "react";
import { Box, Paper, Typography, Stack } from "@mui/material";
import { useContext } from "react";
import CampaignContext from "../provider/CampaignContext";
import CircularProgress from "@mui/material/CircularProgress";

const CampaignInfo = () => {
  const { campaign } = useContext(CampaignContext); // Access campaign from context

  if (!campaign) {
    // Campaign data is not available yet, show loading or fallback content
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper sx={{ marginTop: 2 }}>
      <Typography
        component={"span"}
        fontWeight={"bold"}
        variant="h6"
        sx={{
          display: "flex",
          padding: 2,
        }}
      >
        Campaign Information
      </Typography>

        {campaign &&
          (
            <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
              <Stack sx={{ pl: 6, textAlign: "right" }}>
                <Typography sx={{ marginBottom: 1 }}>Name</Typography>
                <Typography sx={{ marginBottom: 1 }}>Simulation</Typography>
                <Typography sx={{ marginBottom: 1 }}>File Name</Typography>
                <Typography sx={{ marginBottom: 1 }}>Execution_Method</Typography>
                <Typography sx={{ marginBottom: 2 }}>Description</Typography>
              </Stack>
              <Stack sx={{ flex: 1, marginLeft: 5 }}>
                <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                  {campaign.name}
                </Typography>
                <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                  {(campaign.payload.simulation.name) || "None"}
                </Typography>
                <Typography sx={{ fontWeight: "bold", marginBottom: 1}}>{campaign.payload.file_name}</Typography>
                <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                  {(campaign.payload.platform) + " - " + campaign.payload.execution}
                  {campaign.transform && (
                    campaign.transform.input_file_type + " Method " + campaign.transform.name
                  )}
                  {campaign.profile &&
                    campaign.profile.in_memory &&
                    " In Memory : " +
                    campaign.profile.in_memory.toString() +
                    "-" +
                    campaign.payload.platform
                  }
                </Typography>
                <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
                  {(campaign.payload.simulation &&
                    campaign.payload.simulation.description &&
                    campaign.payload.simulation.description) ||
                    "None"}
                </Typography>
              </Stack>
            </Box>
          )
        }
    </Paper>
  );
};

export default CampaignInfo;
