import { LoadingButton } from "@mui/lab";
import {
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { FaCheck, FaEdit, FaFolderPlus, FaPlus, FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import TextButton from "../../../Components/Buttons/TextButton";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import AdminAddUserToTenantForm from "../../../Components/Forms/Administration/AdminAddUserToTenantForm";
import ConfigInviteUserForm from "../../../Components/Forms/Config/ConfigInviteUserForm";
import ServiceProviderEditUserForm from "../../../Components/Forms/ServiceProvider/ServiceProviderEditUserForm/ServiceProviderEditUserForm";
import FormModal from "../../../Components/Modals/FormModal";
import { DataContext } from "../../../Context/dataContext";
import { getRequest, postRequest } from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";
import { COLORS } from "../../../Styles/colors";

const ConfigUserManagement = () => {
  // ----- STATES && CONTEXT -----

  const [dataTableRows, setDataTableRows] = useState([]);
  const [dataTableColumns, setDataTableColumns] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [showUserTable, setShowUserTable] = useState(true);
  const [addUserModal, setAddUserModal] = useState(false);
  const [reload, setReload] = useState(true);

  const [dataTableRows2, setDataTableRows2] = useState([]);
  const [dataTableColumns2, setDataTableColumns2] = useState([]);
  const [filteredAction, setFilteredAction] = useState("");

  // Modal States
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  // Contexts
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );
  const user = useAppSelector((state) => state.user);

  // ----- VARIABLES -----

  // ----- FUNCTIONS -----

  const handleAddUserClose = () => {
    setAddUserModal(false);
  };

  // Closes the modal
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * This function handles the creation of columns and rows for MUI Datagrid. Running this function will could the MUI-Datagrid to re-mount.
   * @param {Array.<{name: String, email: String, currentView: Number, superuser: Boolean, userrole: String}>} users
   */
  const populateDataGrid = (users) => {
    const columns = [
      {
        field: "name",
        headerName: "Name",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "userrole",
        headerName: "User Role",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "archived",
        headerName: "Archived",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {
          const handleChange = async (e, userId) => {
            const value = e.target.checked;
            if (value) {
              toast.loading("Archiving selected User");
            } else {
              toast.loading("Unarchiving selected User");
            }
            const res = await postRequest(
              `/react/api/config/user/archived/${userId}/${value}`,
              accessToken
            );
            if (res.status === 200) {
              setReload((current) => !current);
              toast.dismiss();
              if (value) {
                toast.success("User archived successfully");
              } else {
                toast.success("User unarchived successfully");
              }
            } else {
              toast.dismiss();
              if (value) {
                toast.error("Failed to archive user");
              } else {
                toast.error("Failed to unarchive user");
              }
            }
          };

          return (
            <Switch
              disabled={user.role !== "Provider_Admin"}
              onChange={(e) => handleChange(e, params.row.id)}
              checked={params.value}
              inputProps={{ "aria-label": "toggle switch" }}
            />
          );
        },
      },

      {
        field: "passwordreset",
        headerName: "Password Reset",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {
          let options = {
            method: "POST",
            url: process.env.REACT_APP_PASSWORD_RESET_URL,
            headers: { "content-type": "application/json" },
            data: {
              client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
              email: params.row.email,
              connection: "Username-Password-Authentication",
            },
          };

          const onClick = () => {
            axios
              .request(options)
              .then(() => {
                document.getElementById(
                  `pass-btn-${params.row.id}-sent`
                ).style.display = "block";
              })
              .catch((error) => console.error(error));
          };

          return (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <LoadingButton
                id={`pass-btn-${params.row.id}`}
                variant="contained"
                onClick={onClick}
              >
                Reset
              </LoadingButton>
              <Typography
                id={`pass-btn-${params.row.id}-sent`}
                sx={{
                  display: "none",
                  color: COLORS.success.main,
                }}
              >
                Email Sent
              </Typography>
            </Box>
          );
        },
      },
      {
        field: "edit",
        headerName: "Edit User",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {
          const onClick = () => {
            setSelectedUser(params.row.user);
            setEditOpen(true);
          };
          return (
            <IconButton
              onClick={onClick}
              disabled={user.role !== "Provider_Admin"}
            >
              <FaEdit />
            </IconButton>
          );
        },
      },
    ];
    const rows = users.map((user) => {
      return {
        id: user.uuid,
        name: `${user.first || ""} ${user.last || ""}`,
        email: user.user.email,
        superuser: user.superuser,
        userrole: user.role,
        user: user,
        archived: user.archived,
      };
    });

    setDataTableRows(rows);
    setDataTableColumns(columns);
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    /**
     * This function handles the creation of columns and rows for MUI Datagrid. Running this function will could the MUI-Datagrid to re-mount.
     * @param {Array.<{email: String, customer: String, inviteCode: String, created: Date, valid: Boolean, result: String}>} invites
     */
    const populateDataGrid2 = (invites) => {
      const columns = [
        {
          field: "email",
          headerName: "Email",
          minWidth: 100,
          flex: 1,
        },
        {
          field: "customer",
          headerName: "Customer",
          minWidth: 100,
          flex: 1,
        },
        {
          field: "inviteCode",
          headerName: "Invite Code",
          minWidth: 100,
          flex: 1,
        },
        {
          field: "created",
          headerName: "Created",
          minWidth: 100,
          flex: 1,
        },
        {
          field: "valid",
          headerName: "Valid?",
          minWidth: 100,
          flex: 0,
          renderCell: (params) =>
            params.row.valid ? (
              <IconButton>
                <FaCheck color="green" />
              </IconButton>
            ) : (
              <IconButton>
                <FaTimes color={COLORS.error.main} />
              </IconButton>
            ),
        },
        {
          field: "result",
          headerName: "Email Send Result",
          minWidth: 100,
          flex: 1,
        },
        {
          field: "is_used",
          headerName: "Resend Invite",
          minWidth: 100,
          flex: 1,
          renderCell: (params) =>
            !params.row.is_used && (
              <Button onClick={() => resendInvite(params)} variant="contained">
                Resend Invite
              </Button>
            ),
        },
      ];

      const rows = invites.map((invite) => {
        return {
          id: invite.uuid,
          email: invite.email,
          customer: invite.customer.display_name,
          inviteCode: invite.inviteCode,
          created: moment(invite.created).format("MMMM Do YYYY, h:mm a"),
          valid: invite.valid,
          result: invite.email_result || "None",
          is_used: invite.is_used,
        };
      });

      setDataTableRows2(rows);
      setDataTableColumns2(columns);
    };

    const resendInvite = async (params) => {
      const formData = {
        email: params.row.email,
        customer: params.row.customer,
      };
      await toast.promise(
        postRequest(
          "/react/api/config/resend_user_invite",
          accessToken,
          formData,
          true
        ),
        {
          pending: `Resending invite to ${params.row.email}`,
          success: "Successfully re-sent invitation",
          error: "Failed to re-send invite",
        }
      );
    };

    // Retrieves all the users for the current cx view.
    const getUsers = async () => {
      const res = await getRequest(`/react/api/${current_customer.uuid}/config/users`, accessToken);
      populateDataGrid(res.data.users);
    };

    // Retrieves all the invites for the current cx view.
    const getUserInvites = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/config/user_invites`,
        accessToken
      );
      populateDataGrid2(res.data.invites);
    };

    getUsers();
    getUserInvites();
    // TODO - Add a dependency to the useEffect
    // eslint-disable-next-line
  }, [current_customer, accessToken, reload]);

  return (
    <>
      <Stack spacing={1}>
        {/* Breadcrumbs */}
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.primary">Config</Typography>
          <Typography color="text.primary">User Management</Typography>
        </Breadcrumbs>

        {/* Page Title  && Button */}
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h3">User Management</Typography>
          {/* Button Component */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            {!showUserTable && (
              <TextButton
                tooltip="Create New Invite"
                icon={FaPlus}
                href="/"
                onClick={() => setOpen(true)}
              />
            )}

            {user.superuser && showUserTable && (
              <TextButton
                tooltip="Add existing user"
                icon={FaFolderPlus}
                href="/"
                onClick={() => setAddUserModal(true)}
              />
            )}
          </Box>
        </Stack>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <ButtonGroup>
            <Button
              variant={showUserTable === true ? "contained" : "outlined"}
              color="primary"
              onClick={() => setShowUserTable((current) => !current)}
            >
              Users
            </Button>
            <Button
              variant={showUserTable === false ? "contained" : "outlined"}
              color="primary"
              onClick={() => setShowUserTable((current) => !current)}
            >
              UserInvites
            </Button>
          </ButtonGroup>
        </Stack>

        {/* Template Grid */}
        {showUserTable ? (
          <Stack>
            <DataGridWithStyles
              name="config-users"
              autoHeight
              paper
              rows={dataTableRows}
              columns={dataTableColumns}
              excel
              filter
              columnBar
            />
          </Stack>
        ) : (
          <Stack>
            {/* Invite Grid */}
            <ButtonGroup>
              <Button
                variant={filteredAction === "" ? "contained" : "outlined"}
                color="primary"
                onClick={() => setFilteredAction("")}
              >
                All
              </Button>
              <Button
                variant={filteredAction === false ? "contained" : "outlined"}
                color="primary"
                onClick={() => setFilteredAction(false)}
              >
                Unused Invites
              </Button>
              <Button
                variant={filteredAction === true ? "contained" : "outlined"}
                color="primary"
                onClick={() => setFilteredAction(true)}
              >
                Used Invites
              </Button>
            </ButtonGroup>
            {/* Invite Grid */}
            <DataGridWithStyles
              name="config-users"
              autoHeight
              paper
              rows={dataTableRows2}
              columns={dataTableColumns2}
              excel
              filter
              columnBar
              filterModel={{
                items: [
                  {
                    columnField: "is_used",
                    operatorValue: "equals",
                    value: String(filteredAction),
                  },
                ],
              }}
            />
          </Stack>
        )}
      </Stack>

      <FormModal setOpen={setEditOpen} open={editOpen}>
        <ServiceProviderEditUserForm
          user={selectedUser}
          setOpen={setEditOpen}
        />
      </FormModal>

      {/* Modal for Create New Invite form */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="Modal-Container">
          <ConfigInviteUserForm setOpen={setOpen} />
        </div>
      </Modal>

      <Modal
        open={addUserModal}
        onClose={handleAddUserClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="Modal-Container">
          <AdminAddUserToTenantForm
            setAddUserModal={setAddUserModal}
            customer={current_customer.uuid}
          />
        </div>
      </Modal>
    </>
  );
};

export default ConfigUserManagement;
