import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../../Context/dataContext";
import { postRequest } from "../../../../Helpers/httpRequests";
import FormRowWithDetails from "../../../FormHelpers/FormRowWithDetails/FormRowWithDetails";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../Redux/app/hooks";

const MicrosoftConfigForm = (props) => {
  // ----- STATES && CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const customer = useAppSelector((state) => state.customer);
  const [showClientSecret, setShowClientSecret] = useState(false);
  const { onFinish, setModalOpen, toolData } = props;
  const [formData, setFormData] = useState({
    securityTool: toolData.uuid,
    client_id: "",
    client_secret: "",
    tenant_id: "",
    uuid: "",
    status: "resolved",
    classification: "informationalExpectedActivity",
    determination: "securityTesting",
  });

  // ----- VARIABLES -----

  const STATUS_OPTIONS = [
    ["new", "New"],
    ["resolved", "Resolved"],
    ["inProgress", "In Progress"],
  ];

  const CLASSIFICATION_OPTIONS = [
    ["unknown", "Unknown"],
    ["falsePositive", "False Positive"],
    ["truePositive", "True Positive"],
    ["informationalExpectedActivity", "Informational, Expected Activity"],
  ];

  const DETERMINATION_OPTIONS = [
    ["unknown", "Unknown"],
    ["apt", "APT"],
    ["malware", "Malware"],
    ["securityPersonnel", "Security Personnel"],
    ["securityTesting", "Security Testing"],
    ["unwantedSoftware", "Unwanted Software"],
    ["other", "Other"],
    ["multiStagedAttack", "Multi-Staged Attack"],
    ["compromisedUser", "Compromised User"],
    ["phishing", "Phishing"],
    ["maliciousUserActivity", "Malicious User Activity"],
    ["clean", "Clean"],
    ["insufficientData", "Insufficient Data"],
    ["confirmedUserActivity", "Confirmed User Activity"],
    ["lineOfBusinessApplication", "Line of Business Application"],
  ];

  // ----- FUNCTIONS -----
  const handleSubmit = async () => {
    // Trim whitespace from client_id, client_secret, and tenant_id
    setFormData((prevFormData) => ({
      ...prevFormData,
      client_id: prevFormData.client_id.trim(),
      client_secret: prevFormData.client_secret.trim(),
      tenant_id: prevFormData.tenant_id.trim(),
    }));
    if (toolData.action === "edit" || toolData.action === "automation") {
      let loading_toast = toast.loading(
        `Attempting to ${toolData.action} Security Tool Integration`,
      );
      const result = await postRequest(
        `/react/api/${customer.uuid}/config/securitytoolconfigs/microsoft/edit`,
        accessToken,
        formData,
      );
      if (result.data.status === 200) {
        toast.dismiss(loading_toast);
        toast.success("Request Successful");
        setModalOpen(false);
        if (onFinish) {
          onFinish();
        }
      } else {
        toast.dismiss(loading_toast);
        toast.error("Failed to connect Security Tool Integration");
      }
    }

    if (toolData.action === "add") {
      let loading_toast = toast.loading(
        `Attempting to ${toolData.action} Security Tool Integration`,
      );
      const result = await postRequest(
        `/react/api/${customer.uuid}/config/securitytoolconfigs/microsoft/add`,
        accessToken,
        formData,
      );
      if (result.data.status === 200) {
        toast.dismiss(loading_toast);
        toast.success("Request Successful");
        setModalOpen(false);
        if (onFinish) {
          onFinish();
        }
      } else {
        toast.dismiss(loading_toast);
        toast.error("Failed to connect Security Tool Integration");
      }
    }
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const setForm = async () => {
      if (toolData.action === "edit" || toolData.action === "automation") {
        // Update to correct data
        setFormData((prev) => ({
          ...prev,
          securityTool: toolData.tool.uuid,
          client_id: toolData.tool.tool_config.client_id,
          client_secret: toolData.tool.tool_config.client_secret,
          tenant_id: toolData.tool.tool_config.tenant_id,
          uuid: toolData.tool.tool_config.uuid,
          status: toolData.tool.tool_config.status,
          classification: toolData.tool.tool_config.classification,
          determination: toolData.tool.tool_config.determination,
        }));
      }
    };
    setForm();
  }, [accessToken, toolData]);

  let form_jsx = <></>;

  if (toolData.action === "add" || toolData.action === "edit") {
    form_jsx = (
      <div>
        <Typography variant="h5">Windows Defender Configuration</Typography>
        <Divider style={{ margin: "1rem 0" }} />
        <Stack spacing={2}>
          {/* Client ID */}
          <TextField
            required
            id="tool-client-id"
            label="Client ID"
            value={formData.client_id}
            onChange={(e) =>
              setFormData({ ...formData, client_id: e.target.value })
            }
          />
          {/* Client secret */}
          <TextField
            required
            type={showClientSecret ? "text" : "password"}
            id="tool-client-secret"
            label="Client Secret"
            value={formData.client_secret}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowClientSecret(!showClientSecret)}
                  >
                    {showClientSecret ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) =>
              setFormData({ ...formData, client_secret: e.target.value })
            }
          />

          {/* Tenant ID */}
          <TextField
            required
            id="tool-tenant-id"
            label="Tenant ID"
            value={formData.tenant_id}
            onChange={(e) =>
              setFormData({ ...formData, tenant_id: e.target.value })
            }
          />
        </Stack>
      </div>
    );
  } else if (toolData.action === "automation") {
    form_jsx = (
      <>
        <Typography variant="h5">
          Windows Defender Automated Ticket Closing
        </Typography>
        <Divider style={{ margin: "1rem 0" }} />
        <Stack spacing={2}>
          <FormRowWithDetails
            type="select"
            options={STATUS_OPTIONS}
            label="status"
            formData={formData}
            setFormData={setFormData}
            formKey="status"
            description="The status on Defender to be set"
            required
          />
          <FormRowWithDetails
            type="select"
            options={CLASSIFICATION_OPTIONS}
            label="classification"
            formData={formData}
            setFormData={setFormData}
            formKey="classification"
            description="The classification on Defender to be set"
            required
          />
          <FormRowWithDetails
            type="select"
            options={DETERMINATION_OPTIONS}
            label="determination"
            formData={formData}
            setFormData={setFormData}
            formKey="determination"
            description="The determination on Defender to be set"
            required
          />
        </Stack>
      </>
    );
  }

  return (
    <Stack spacing={2}>
      {form_jsx}
      <Button onClick={handleSubmit} variant="contained">
        Submit
      </Button>
    </Stack>
  );
};

MicrosoftConfigForm.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  onFinish: PropTypes.func,
};

export default MicrosoftConfigForm;
