import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Paper,
  Typography,
  Stack,
} from "@mui/material";
import FormModal from "../../../../Components/Modals/FormModal";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { FaCamera, FaTimesCircle, FaCheck, FaTimes } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import TextButton from "../../../../Components/Buttons/TextButton";
import DataGridWithStyles from "../../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import EndpointDetailsTable from "../../../../Components/Endpoints/EndpointDetailsTable";
import { DataContext } from "../../../../Context/dataContext";
import {
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../../Helpers/httpRequests";
import { useAppSelector } from "../../../../Redux/app/hooks";

const EndpointDetails = () => {
  // ----- STATES && CONTEXT -----

  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer,
  );
  const [reloadEndpoints, setReloadEndpoints] = useState(false);
  const [endpoint, setEndpoint] = useState([]);
  const [screenshots, setScreenshots] = useState([]);
  const [status, setStatus] = useState(false);
  const [agentHost, setAgentHost] = useState([]);
  const [agentVersion, setAgentVersion] = useState([]);
  const [AVs, setAVs] = useState([]);
  const [currentVersion, setCurrentVersion] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [pendingAgentActionsRowsRC, setPendingAgentActionsRowsRC] = useState(
    [],
  );
  const [pendingAgentActionsRowsC2, setPendingAgentActionsRowsC2] = useState(
    [],
  );
  const [alertRows, setAlertRows] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  // ----- VARIABLES -----

  const role = useAppSelector((state) => state.user.role);
  const { endpointUUID } = useParams();

  const pendingAgentActions = [
    { field: "Action", headerName: "Action", minWidth: 100, flex: 1 },
    { field: "Name", headerName: "Name", minWidth: 100, flex: 1 },
    {
      field: "Simulation",
      headerName: "Simulation",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <Link
            className="text-links"
            to={`/${current_customer.uuid}/simulations/details/${params.row.Simulation_uuid}`}
          >
            {params.row.Simulation}
          </Link>
        );
      },
    },
    { field: "Status", headerName: "Status", maxWidth: 80, flex: 1 },
    { field: "Report", headerName: "Report", maxWidth: 70, flex: 1 },
    {
      field: "Actions",
      headerName: "Actions",
      maxWidth: 180,
      flex: 1,
      renderCell: (params) => {
        return params.row.Status === "running" ? (
          <Button
            disabled={params.row.Actions === "" ? true : false}
            variant="contained"
            style={{ minWidth: 4, minHeight: 4, fontSize: 9 }}
            onClick={() => forceComplete(params)}
          >
            Force Complete
          </Button>
        ) : (
          <Button
            disabled={params.row.Actions === "" ? true : false}
            variant="contained"
            style={{ minWidth: 4, minHeight: 4, fontSize: 9 }}
            onClick={() => deleteOperation(params.row.id)}
          >
            Delete Operation
          </Button>
        );
      },
    },
  ];

  const alertColumns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <Link
            to={`/${current_customer.uuid}/alertvalidation/alerts/history/${params.row.id}`}
          >
            {params.row.name}
          </Link>
        );
      },
    },
    {
      field: "active",
      headerName: "Active",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return params.row.active ? <FaCheck /> : <FaTimes />;
      },
    },
  ];

  // ----- FUNCTIONS -----

  const deleteScreenshot = async () => {
    const res = await postRequest(
      `/react/api/${current_customer.uuid}/endpoints/screenshot/delete/${endpointUUID}`,
      accessToken,
    );
    if (res.status === 200) {
      setReloadEndpoints(true);
      setDeleteModal(false);
    }
  };

  const refreshScreenshot = async () => {
    const res = await postRequest(
      `/react/api/${current_customer.uuid}/endpoints/actions/screenshot/${endpointUUID}`,
      accessToken,
    );
    if (res.status === 200) {
      setReloadEndpoints(true);
    }
  };

  const terminateRun = async () => {
    const res = await postRequest(
      `/react/api/${current_customer.uuid}/endpoints/actions/remove/${endpointUUID}`,
      accessToken,
    );
    if (res.status === 200) {
      setReloadEndpoints(true);
    }
  };

  const forceComplete = async (params) => {
    const res = await postRequest(
      `/react/api/${current_customer.uuid}/endpoints/force-complete/${params.row.Actions}/${endpointUUID}`,
      accessToken,
    );
    if (res.status === 200) {
      setReloadEndpoints(true);
    }
  };

  const deleteOperation = async (uuid) => {
    const res = await deleteRequest(
      `/react/api/${current_customer.uuid}/endpoints/deleteOperation/${uuid}`,
      accessToken,
    );
    if (res.status === 200) {
      setReloadEndpoints(true);
    }
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getData = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/endpoints/detail/${endpointUUID}`,
        accessToken,
      );
      try {
        setEndpoint(res.data.endpoints);
        setScreenshots(res.data.screenshots);
        setAgentHost(res.data.agent_host);
        setAgentVersion(res.data.agent_version);
        setAVs(res.data.avs);
        setSessions(res.data.sessions);
        setCurrentVersion(res.data.currentVersion);
        populatePendingAgentActionsRC(res.data.runningCampaigns);
        populatePendingAgentActionsC2(res.data.c2ops);
        populateAlerts(res.data.alerts);
        setStatus(true);
      } catch (err) {
        console.log("Error while trying to set States from getData()", err);
      }
    };

    getData();

    const populatePendingAgentActionsRC = (rcs) => {
      const rows = rcs.map((rc) => {
        return {
          id: rc.uuid,
          Action: "Campaign",
          Name: operation_choices[rc.name],
          Simulation: rc?.payload?.name || "",
          Simulation_uuid: rc?.payload?.uuid || "",
          Status: "running",
          Actions: rc.uuid,
        };
      });
      setPendingAgentActionsRowsRC(rows);
    };

    const populatePendingAgentActionsC2 = (C2S) => {
      const rows = C2S.map((c2) => {
        return {
          id: c2.uuid,
          Action: c2.operation,
          Name: operation_choices[c2.operation],
          Simulation: c2.campaign_v2?.payload?.simulation?.name || "",
          Simulation_uuid: c2.campaign_v2?.payload?.simulation?.uuid || "",
          Status: "Pending",
          Actions: c2.campaign_v2.uuid || "",
        };
      });
      setPendingAgentActionsRowsC2(rows);
    };

    const populateAlerts = (alerts) => {
      const rows = alerts.map((alert) => {
        return {
          id: alert.id,
          name: alert.name,
          active: alert.active,
        };
      });
      setAlertRows(rows);
    };

    const operation_choices = {
      "host-info": "Gather Host Discovery Info",
      update: "Update the Agent Software",
      config: "Update the configuration file",
      remove: "Uninstall the agent",
      campaign: "Campaign Information",
      screenshot: "Take a screenshot of the host systems",
      logfile: "Upload the current log file for review",
      key: "Encryption Key Change",
      "kill-task": "Kill current running simulation",
      proxy: "Enable the local agent C2 proxy service",
    };
  }, [current_customer, accessToken, reloadEndpoints, endpointUUID]);

  if (status === false) {
    return (
      <>
        <Typography align="center">
          <CircularProgress />{" "}
        </Typography>
      </>
    );
  } else {
    return (
      <>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/">Home</Link>
          <Link to={`/${current_customer.uuid}/simulations/endpoints/list`}>
            Endpoints
          </Link>
          <Typography color="text.primary">Endpoint Details</Typography>
        </Breadcrumbs>

        <Box
          sx={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Typography fontWeight={"bold"} variant="h4">
            Endpoint Details | {endpoint["name"]}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <TextButton
              role={role}
              tooltip="Refresh Screenshot"
              onClick={refreshScreenshot}
              icon={FaCamera}
            />
            <TextButton
              role={role}
              tooltip="Terminate Run"
              onClick={terminateRun}
              icon={FaTimesCircle}
            />
          </Box>
        </Box>

        <Box sx={{ display: "flex" }}>
          <Box
            className="left-box"
            sx={{ flex: 1, paddingRight: 1, marginTop: 1 }}
          >
            <Paper sx={{ marginBottom: 2, padding: 2 }}>
              <Box>
                {screenshots.image !== "" ? (
                  <>
                    <Typography
                      component={"span"}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      <img
                        src={`data:image/jpeg;base64,${screenshots.image}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          maxHeight: "25rem",
                          marginBottom: 5,
                        }}
                        alt={endpoint.name}
                      />
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Box className="rightBox" sx={{ width: "100%" }}>
                        <>
                          {screenshots.timestamp && (
                            <Typography>
                              Screenshot taken at{" "}
                              {moment(screenshots.timestamp).format(
                                "MMM D,YYYY hh:mm a",
                              )}
                            </Typography>
                          )}
                        </>
                      </Box>
                      <Box className="leftBox" sx={{ width: "100%" }}>
                        <Button
                          disabled={role === "View_Only"}
                          style={{
                            float: "right",
                            opacity: role === "View_Only" ? 0.5 : 1,
                            marginBottom: 1,
                          }}
                          variant="contained"
                          color={"error"}
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeleteModal(true);
                          }}
                          title="Delete Screenshots"
                        >
                          <FaTimesCircle /> &nbsp;Delete Screenshots for this
                          Endpoint
                        </Button>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    <Typography
                      component={"span"}
                      variant="h6"
                      textAlign={"right"}
                    >
                      No screenshots exist for this endpoint.
                    </Typography>
                  </>
                )}
              </Box>
            </Paper>

            <EndpointDetailsTable
              endpoint={endpoint}
              agentHost={agentHost}
              agentVersion={agentVersion}
              AVs={AVs}
              currentVersion={currentVersion}
              sessions={sessions}
            />
          </Box>

          <Box
            className="right-box"
            sx={{ flex: 1, paddingLeft: 3, marginTop: 1 }}
          >
            {pendingAgentActionsRowsC2.length > 0 &&
            pendingAgentActionsRowsRC.length > 0 ? (
              <>
                <Paper
                  sx={{ marginBottom: 2, padding: 2, flex: 1, height: "auto" }}
                >
                  <Box
                    flexDirection={"row"}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography fontWeight={"bold"} variant="h6">
                      Pending Agent Actions
                    </Typography>
                    <Button
                      sx={{ marginBottom: 1 }}
                      variant="contained"
                      onClick={() => deleteOperation("all")}
                    >
                      Clear All Pending Actions
                    </Button>
                  </Box>
                  <DataGridWithStyles
                    name="pendingAgentActions"
                    autoHeight
                    rows={[
                      ...pendingAgentActionsRowsC2,
                      ...pendingAgentActionsRowsRC,
                    ]}
                    columns={pendingAgentActions}
                  />
                </Paper>
              </>
            ) : (
              <Alert severity="info" sx={{ marginBottom: 2 }}>
                No Pending Agent Actions
              </Alert>
            )}
            {alertRows.length > 0 ? (
              <>
                <Paper
                  sx={{ marginBottom: 2, padding: 2, flex: 1, height: "auto" }}
                >
                  <Typography fontWeight={"bold"} variant="h6">
                    Scheduled Alerts
                  </Typography>
                  <DataGridWithStyles
                    name="sched-alerts"
                    autoHeight
                    rows={alertRows}
                    columns={alertColumns}
                  />
                </Paper>
              </>
            ) : (
              <Alert severity="info">
                No Scheduled Exercises on this endpoint
              </Alert>
            )}
          </Box>
          {/* Delete Modal */}
          <FormModal open={deleteModal} setOpen={setDeleteModal}>
            <Box component="form">
              <Typography
                variant="h6"
                style={{ textAlign: "center", marginBottom: 4 }}
              >
                Are you sure you want to remove Screenshot for {endpoint.name}?
              </Typography>
              <Stack direction="column" spacing={2}>
                <Button onClick={() => deleteScreenshot()} variant="contained">
                  Yes
                </Button>
              </Stack>
            </Box>
          </FormModal>
        </Box>
      </>
    );
  }
};

export default EndpointDetails;
