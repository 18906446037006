import React, { useEffect, useContext, useState } from "react";
import { getRequest, postRequest } from "../../../Helpers/httpRequests";
import {
  Divider,
  Stack,
  Typography,
  Box,
  CircularProgress,
  Tooltip,
  Fade,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataContext } from "../../../Context/dataContext";
import PropTypes from "prop-types";
import moment from "moment";
import DataGridWithStyles from "../../DataGrid/DataGridWithStyles";
import {
  FaTimesCircle,
  FaCheckCircle,
  FaSpinner,
  FaHourglass,
} from "react-icons/fa";
import { COLORS } from "../../../Styles/colors";
import "./style.css";
import { useAppSelector } from "../../../Redux/app/hooks";

const ProjectAddExistingCampaign = ({ setOpen, projectUUID, filters = {} }) => {
  // ----- CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  // ----- STATES -----

  const [dataTableRows, setDataTableRows] = useState([]);
  const [campaignsToAdd, setCampaignsToAdd] = useState([]);
  const [campaigns, setCampaigns] = useState([]);

  /**
   * Used for handling the props/children of <LoadingButton>
   */
  const [loadingBtn, setLoadingBtn] = useState({
    loading: false,
    color: "primary",
    text: "Submit",
    helperText: "",
    helperTextColor: "green",
  });

  // ---- Variables ------

  const columns = [
    {
      field: "name",
      headerName: "Campaign Name",
      minWidth: 200,
      flex: 2,
      renderCell: (params) => {
        return (
          <Tooltip
            title={params.row.name}
            placement="top"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            arrow
          >
            <Typography
              sx={{ fontSize: 13 }}
              className={params.id !== params.value ? "text-links" : ""}
            >
              {params.row.name}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "campaign",
      headerName: "Endpoint",
      minWidth: 200,
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            <Typography sx={{ fontSize: 13 }} className="text-links">
              {params.row.endpoint}
            </Typography>
          </>
        );
      },
    },
    { field: "simulation", headerName: "Simulation", minWidth: 200, flex: 2 },
    // {
    //   field: "Execution_method",
    //   headerName: "Execution Method",
    //   minWidth: 200,
    //   flex: 1,
    // },
    {
      field: "status",
      headerName: "Status",
      maxWidth: 130,
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            {params.row.status === "completed" && (
              <Stack
                direction="row"
                sx={{ justifyContent: "space-between", width: "100%" }}
              >
                <FaCheckCircle size={20} color={COLORS.success.main} />
                <Typography>Completed</Typography>
              </Stack>
            )}
            {params.row.status === "ready" && (
              <Stack
                direction="row"
                sx={{ justifyContent: "space-between", width: "100%" }}
              >
                <FaHourglass size={20} color={COLORS.primary.main} />
                <Typography>Ready</Typography>
              </Stack>
            )}
            {params.row.status === "running" && (
              <Stack
                direction="row"
                sx={{ justifyContent: "space-between", width: "100%" }}
              >
                <FaSpinner size={20} color="orange" className="spinner" />
                <Typography>Running</Typography>
              </Stack>
            )}
            {params.row.status === "failed" && (
              <Stack
                direction="row"
                sx={{ justifyContent: "space-between", width: "100%" }}
              >
                <FaTimesCircle size={20} color={COLORS.error.main} />
                <Typography>Failed</Typography>
              </Stack>
            )}
          </>
        );
      },
    },
    { field: "created", headerName: "Created", minWidth: 140, flex: 1 },
    {
      field: "score",
      headerName: "Score",
      maxWidth: 100,
      flex: 1.1,
      renderCell: (params) => {
        let color = "success";
        if (params.row.score * 100 < 50) {
          color = "error";
        } else if (params.row.score * 100 < 75) {
          color = "warning";
        }
        return (
          <div style={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
              variant="determinate"
              value={params.row.score * 100}
              size={45}
              thickness={3}
              color={color}
            />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Typography variant="body2">
                {`${(params.row.score * 100).toFixed(0)}%`}
              </Typography>
            </div>
          </div>
        );
      },
    },
  ];

  // ----- Functions -----

  /**
   * Handles the form submission.
   * @param {Event} e
   */

  const populateDataGrid = (campaigns) => {
    const compare = (a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else return 0;
    };
    const rows = campaigns.map((campaign, index) => {
      return {
        id: campaign.uuid,
        campaign_uuid: campaign.uuid,
        endpoint_uuid: campaign.endpoint?.uuid,
        name: campaign.name,
        endpoint: campaign.endpoint.name,
        simulation: campaign.payload.simulation.name,
        Execution_method:
          campaign?.payload?.execution +
          "-" +
          campaign?.payload?.platform +
          " Method" +
          campaign?.process_injection_method +
          " PI Target: " +
          campaign?.pi_target?.name,
        status: campaign.status,
        created: moment(campaign.created).format("M/D/YY,hh:mm a"),
        score: campaign.score,
      };
    });
    setDataTableRows(rows.sort(compare));
  };

  const handleSubmit = async (e) => {
    // prevent page reload on submit
    e.preventDefault();

    // set submit btn to loading
    setLoadingBtn((prev) => ({ ...prev, loading: true }));
    const data = {
      campaigns: campaignsToAdd,
      project: projectUUID,
    };
    const res = await postRequest(
      `/react/api/${current_customer.uuid}/projects/addExistingCampaign`,
      accessToken,
      data,
      true
    );
    setLoadingBtn((prev) => ({ ...prev, loading: false }));

    if (res.status === 200) {
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Success",
        color: "success",
        helperText: "Success",
        helperTextColor: "#2e7d32", // green
      }));

      /**
       * If this form is contained in a modal, you can pass the setOpen state to this component.
       * This will wait 1.5 seconds and then close the modal
       * Add 'open' state to this components parent useEffect dependencies array to have your page reload the data.
       */
      if (setOpen !== undefined) {
        setTimeout(() => setOpen(false), 1500);
      }
    } else {
      // handle errors here
      setLoadingBtn((prev) => ({
        loading: false,
        text: "Error - Try again",
        color: "error",
        helperText: "Form submission failed",
        helperTextColor: "#d32f2f", // red
      }));
      console.log(
        "%cerror ProjectAddExistingCampaign.jsx handleSubmit()",
        "color: red; display: block; width: 100%;",
        "Failed to submit form"
      );
    }
  };

  // const onRowClick = (e) => {
  //   const index = campaignsToAdd.findIndex((c) => c.id === e.row.id);
  //   if (index !== -1) {
  //     campaignsToAdd.splice(index, 1);
  //   } else {
  //     campaignsToAdd.push(e.row);
  //   }
  //   setCampaignsToAdd([...campaignsToAdd]);
  // };

  const onRowCheckboxClick = (arrayOfUUIDs) => {
    const selectedCampaigns = campaigns.filter((camp) =>
      arrayOfUUIDs.includes(camp.uuid)
    );

    const campsToAdd = selectedCampaigns.map((campaign) => {
      return {
        id: campaign.uuid,
        campaign_uuid: campaign.uuid,
        endpoint_uuid: campaign.endpoint?.uuid,
      };
    });
    setCampaignsToAdd(campsToAdd);
  };

  // ----- On page load -----
  useEffect(() => {
    const getData = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/campaigns`,
        accessToken,
        filters
      );
      if (res.status === 200) {
        populateDataGrid(res.data.campaigns);
        setCampaigns(res.data.campaigns);
      }
    };

    getData();
  }, [current_customer, accessToken, filters]);

  return (
    <Box>
      {/* Form Header */}
      <Typography variant="h5">Add Existing Campaigns</Typography>
      <Alert severity="info">
        Campaigns cannot be a part of an existing project and must be within the
        project date range
      </Alert>

      <Divider style={{ marginBottom: "0.5rem" }} />

      {/* FORM */}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Stack direction="column" spacing={2}>
          <DataGridWithStyles
            name="project-existing-campaigns"
            rows={dataTableRows}
            columns={columns}
            checkboxSelection
            autoHeight
            // onRowClick={onRowClick}
            onSelectionModelChange={onRowCheckboxClick}
          />

          <Divider />

          <Typography>Selected Campaigns:</Typography>
          <Stack sx={{ marginLeft: "1rem", paddingLeft: "1rem" }}>
            {campaignsToAdd.map((campaign) => {
              return (
                <Typography component={"li"} key={campaign.id}>
                  {campaign.name}
                </Typography>
              );
            })}
          </Stack>

          {/* helper text */}
          <Typography color={loadingBtn.helperTextColor} variant="subtitle2">
            {loadingBtn.helperText}
          </Typography>

          {/* Submit Button */}
          <LoadingButton
            loading={loadingBtn.loading}
            color={loadingBtn.color}
            type="submit"
            variant="contained"
          >
            {loadingBtn.text}
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
};

ProjectAddExistingCampaign.propTypes = {
  setOpen: PropTypes.func,
  modal: PropTypes.bool,
};

export default ProjectAddExistingCampaign;
