import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Chip,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography
} from "@mui/material";
import React from "react";
import {
  FaBan,
  FaExclamationTriangle,
  FaEyeSlash,
  FaFileCode,
  FaPlus
} from "react-icons/fa";
import SelectSearch from "react-select-search";
import "react-select-search/style.css";
import { COLORS } from "./../../../Styles/colors";
import ExpandableText from "./ExpandableText";
import InputTypeSelector from "./InputTypeSelector";
import { PaddedFileModule } from "./PaddedFileModule";
import {
  embedHandleChange,
  embedUniFile,
  getRemainingActionsCount,
  handleAddActionClick,
  handleAddActionClose,
  handleArgChipClick,
  handleArgdelete,
  handleModuleChange,
  handleNoteChange,
  handleOptionalArgumentsClick,
  handleReqOptChange,
  handleTechniqueChangeAdd,
  handleTechniqueChangeRemove,
} from "./functions";
import classes from "./style.module.css";
import { chipStylesArgs } from "./styles";

const AddActionDrawer = ({
  addActionModal,
  setAddActionModal,
  addActionIndex,
  setAddActionIndex,
  data,
  setData,
  actionData,
  setActionData,
  options,
  argEditIndex,
  setArgEditIndex,
  setChangesMade,
  techniques,
  theme,
  argInputValue,
  setArgInputValue,
  testingResults,
  setTestHistory,
  edittedAction,
  uniFiles,
  user
}) => {
  return (
    <div>
      <Drawer
        open={addActionModal}
        anchor="right"
        onClose={() => handleAddActionClose(setAddActionModal)}
        variant="persistent"
        sx={{
          width: "41%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "41%",
            boxSizing: "border-box",
          },
        }}
      >
        {/* Fixed Content */}
        <Stack sx={{ padding: 2, marginTop: 7 }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h5">
                Add Action {addActionIndex + 1}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Button
                sx={{ ml: 2 }} // Add left margin to separate buttons
                variant="outlined"
                disabled={
                  data.actions.length >= 40 ||
                  actionData.arguments.some((arg) => arg.startsWith("(")) ||
                  actionData.module === "" ||
                  actionData.expected_outcome === null ||
                  (actionData.module === "run" &&
                    actionData.arguments.length === 0) ||
                  (actionData.module === "padded_file" &&
                    actionData.arguments[5].length === 0)
                }
                onClick={() =>
                  handleAddActionClick(
                    testingResults,
                    data,
                    setTestHistory,
                    setData,
                    addActionIndex,
                    setAddActionIndex,
                    setActionData,
                    setChangesMade,
                    actionData
                  )
                }
              >
                {data.actions.length >= 40 ? (
                  <>Action Limit reached</>
                ) : (
                  <>Add Action</>
                )}
              </Button>
              <Button
                variant="outlined"
                onClick={() => setAddActionModal(false)}
              >
                Cancel
              </Button>
            </Box>
          </Box>
          <Box>
            <Typography variant="h7">
              Remaining Actions - {getRemainingActionsCount(data)}
            </Typography>
          </Box>
        </Stack>

        {/* Scrollable Content */}
        <Box sx={{ maxHeight: "calc(100% - 175px)", overflowY: "auto" }}>
          <Stack spacing={1.5} sx={{ padding: 2 }}>
            {actionData.module && (
              <Typography variant="h6">
                {
                  options.find((o) => o.module.name === actionData.module)
                    .module.notes
                }
              </Typography>
            )}
            {/* Module */}
            <FormControl required>
              <InputLabel id="module">Module</InputLabel>
              <Select
                label="module"
                value={actionData.module}
                onChange={(e) =>
                  handleModuleChange(e, options, setActionData, actionData)
                }
              >
                {options.length > 0 &&
                  options
                    .sort((a, b) => a.module.name.localeCompare(b.module.name)) // Sort alphabetically
                    .map((option, index) => (
                      <MenuItem key={index} value={option.module.name}>
                        {option.module.name}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>

            {/* Note */}
            <FormControl>
              <TextField
                multiline
                style={{
                  height: "auto",
                  fontSize: "20px",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  width: "100%",
                }}
                label="Add Optional Note"
                value={actionData.note}
                onChange={(e) =>
                  handleNoteChange(e, setActionData, setChangesMade)
                }
              ></TextField>
            </FormControl>

            <Stack direction="row" spacing={2} alignItems="center">
              {/* Clean Up */}
              <FormControlLabel
                sx={{ maxWidth: 170 }}
                label="Clean Up Step"
                labelPlacement="start"
                control={
                  <Switch
                    checked={actionData.clean_up}
                    onChange={(event) =>
                      setActionData((prevData) => ({
                        ...prevData,
                        clean_up: event.target.checked,
                      }))
                    }
                    name="cleanUpToggle"
                  />
                }
              />
              {/* Action Time Out */}
              <FormControl>
                <TextField
                  type="number"
                  label="Action time out in seconds"
                  value={actionData.time_out}
                  onChange={(event) =>
                    setActionData((prevData) => ({
                      ...prevData,
                      time_out: event.target.value,
                    }))
                  }
                  name="timeOutInput"
                  inputProps={{ min: "60" }} // Optional: Set minimum value
                />
              </FormControl>
            </Stack>

            {/* Arguments */}
            {actionData.module !== "padded_file" ? (
              <Stack
                style={{
                  marginBottom: 2,
                  border: "2px solid #d0d0d0",
                  borderRadius: "10px",
                  padding: "5px",
                }}
              >
                {actionData.arguments.length === 0 ? (
                  <Alert severity="info">No Current Arguments</Alert>
                ) : (
                  <>
                    {actionData.arguments.some((arg) =>
                      arg.startsWith("(")
                    ) && (
                      <Typography variant="h5">
                        Please fill in all missing inputs
                      </Typography>
                    )}
                    <Typography variant="h6" sx={{ marginBottom: 2 }}>
                      Current Arguments:
                    </Typography>
                  </>
                )}
                <Grid container spacing={2}>
                  {actionData.arguments.map((arg, index) => (
                    <React.Fragment key={index}>
                      {argEditIndex === index && (
                        <Grid item xs={20}>
                          <InputTypeSelector
                            data={data}
                            setData={setData}
                            arg={arg}
                            argInputValue={argInputValue}
                            setArgInputValue={setArgInputValue}
                            argEditIndex={argEditIndex}
                            setArgEditIndex={setArgEditIndex}
                            setActionData={setActionData}
                            actionData={actionData}
                            edittedAction={edittedAction}
                            embedHandleChange={embedHandleChange}
                            embedUniFile={embedUniFile}
                            isAddAction={true}
                            module={actionData.module}
                            setChangesMade={setChangesMade}
                            uniFiles={uniFiles}
                            user={user}
                          />
                        </Grid>
                      )}
                      {index % 2 === 0 ? (
                        <Grid item xs={12} key={index}>
                          <Grid container spacing={1} alignItems="center">
                            <Grid item xs={5}>
                              <Chip
                                sx={{ width: "50%", height: "auto", justifyContent: "space-between" }}
                                variant="outlined"
                                onClick={() =>
                                  handleArgChipClick(
                                    index,
                                    arg,
                                    setArgEditIndex,
                                    setArgInputValue
                                  )
                                }
                                onDelete={() =>
                                  handleArgdelete(
                                    index,
                                    setActionData,
                                    setChangesMade
                                  )
                                }
                                label={arg}
                              />
                            </Grid>
                            {index + 1 < actionData.arguments.length ? (
                              <Grid item xs={7}>
                                <Chip
                                  onClick={() =>
                                    handleArgChipClick(
                                      index + 1,
                                      actionData.arguments[index + 1],
                                      setArgEditIndex,
                                      setArgInputValue
                                    )
                                  }
                                  sx={{
                                    ...chipStylesArgs.chip,
                                    backgroundColor: actionData.arguments[
                                      index + 1
                                    ].startsWith("(Input")
                                      ? COLORS.error.light
                                      : COLORS.success.light, // Change "red" to your desired background color
                                  }}
                                  label={
                                    <div style={chipStylesArgs.labelContainer}>
                                      <ExpandableText
                                        text={actionData.arguments[index + 1]}
                                      />
                                    </div>
                                  }
                                />
                              </Grid>
                            ) : null}
                          </Grid>
                        </Grid>
                      ) : null}
                    </React.Fragment>
                  ))}
                </Grid>
                <Divider sx={{ marginTop: 2 }} />
                {actionData.module &&
                options.find((o) => o.module.name === actionData.module).module
                  ?.required_options ? (
                  <>
                    {!(
                      actionData.module === "run" &&
                      actionData.arguments.length > 0
                    ) && (
                      <>
                        <Typography>
                          Required Option for {actionData.module}{" "}
                          {"(Please Select One)"}:{" "}
                        </Typography>
                        <Box sx={{ flexDirection: "column" }}>
                          {actionData.module &&
                            options
                              .find((o) => o.module.name === actionData.module)
                              .module?.required_options.map((arg, index) => (
                                <div key={index}>
                                  <Select
                                    value={
                                      Array.from(arg).some(
                                        (argItem) => argItem === argInputValue
                                      )
                                        ? argInputValue
                                        : ""
                                    }
                                    onChange={(e) =>
                                      handleReqOptChange(
                                        e,
                                        setActionData,
                                        setChangesMade
                                      )
                                    }
                                  >
                                    <InputLabel>Choose an option</InputLabel>
                                    {arg.map((argItem, index) => (
                                      <MenuItem key={index} value={argItem}>
                                        {argItem.arg} - {argItem.description}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </div>
                              ))}
                        </Box>
                        <Divider sx={{ marginTop: 1 }} />
                      </>
                    )}
                  </>
                ) : null}

                {actionData.module &&
                  options
                    .find((o) => o.module.name === actionData.module)
                    .module.args.filter((arg) => arg.required === "False")
                    .length > 0 && (
                    <>
                      <Typography>
                        Optional Add In Arguments {"(Click to Add)"}:{" "}
                      </Typography>
                      <Box sx={{ flexDirection: "column" }}>
                        {actionData.module &&
                          options
                            .find((o) => o.module.name === actionData.module)
                            .module.args.filter(
                              (arg) => arg.required === "False"
                            )
                            .map((arg, index) => (
                              <div key={index}>
                                <Typography variant="h5">
                                  {arg.arg}{" "}
                                  <Typography
                                    component={"span"}
                                    sx={{ size: 5 }}
                                  >
                                    {arg.description}{" "}
                                  </Typography>
                                  <Button
                                    variant="contained"
                                    onClick={() =>
                                      handleOptionalArgumentsClick(
                                        arg,
                                        setActionData,
                                        setChangesMade
                                      )
                                    }
                                  >
                                    <FaPlus />
                                  </Button>
                                </Typography>
                              </div>
                            ))}
                      </Box>
                    </>
                  )}
              </Stack>
            ) : (
              <PaddedFileModule
                actionData={actionData}
                setActionData={setActionData}
              />
            )}

            {/* TTPs */}
            <FormControl required>
              <Stack
                spacing={1}
                sx={{
                  border: "2px solid #d0d0d0",
                  borderRadius: "10px",
                  padding: "5px",
                }}
              >
                <Box>
                  <SelectSearch
                    className={classes}
                    multiple
                    search
                    placeholder="Search Techniques"
                    value={actionData.ttps}
                    onChange={(e) =>
                      handleTechniqueChangeAdd(e, setActionData, setChangesMade)
                    }
                    options={techniques
                      .filter((tech) => {
                        return !actionData.ttps.flat().includes(tech.attack_id);
                      })
                      .map((tech) => ({
                        name: `${tech.attack_id} - ${tech.name}`,
                        value: tech.attack_id,
                      }))}
                  />
                </Box>
                <Box>
                  <Divider />
                  <div>
                    {actionData.ttps.length === 0 ? (
                      <Alert severity="info">No TTPS Selected</Alert>
                    ) : (
                      <>
                        <Typography sx={{ marginTop: 1 }}>
                          Selected TTPS
                        </Typography>
                        {actionData.ttps.map((techniqueId) => {
                          const selectedTechnique = techniques.find(
                            (tech) => tech.attack_id === techniqueId
                          );
                          return (
                            <Chip
                              key={techniqueId}
                              label={
                                <Box
                                  display="flex"
                                  alignItems="center"
                                >
                                  {selectedTechnique && (
                                    <Box
                                      sx={{
                                        flex: 1,
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      {selectedTechnique.name +
                                        "-" +
                                        selectedTechnique.attack_id}
                                    </Box>
                                  )}
                                </Box>
                              }
                              onDelete={() => {
                                handleTechniqueChangeRemove(
                                  actionData.ttps.filter(
                                    (id) => id !== techniqueId
                                  ),
                                  setActionData,
                                  setChangesMade
                                );
                              }}
                              sx={{
                                height: "auto",
                                "& .MuiChip-label": {
                                  display: "block",
                                  whiteSpace: "normal",
                                },
                                marginTop: 1,
                                width: "100%",
                                minHeight: "30px",
                                justifyContent: "space-between",
                                textAlign: "left",
                                backgroundColor: "lightgray",
                              }}
                            />
                          );
                        })}
                      </>
                    )}
                  </div>
                </Box>
              </Stack>
            </FormControl>

            {/* Expected Outcome */}
            <FormControl required>
              <ThemeProvider theme={theme}>
                <ButtonGroup
                  variant="outlined"
                  aria-label="outlined button group"
                  size="large"
                  fullWidth
                >
                  <Tooltip title="Blocked" placement="top" arrow>
                    <Button
                      color="success"
                      variant={
                        actionData.expected_outcome === "blocked"
                          ? "contained"
                          : "outlined"
                      }
                      sx={{
                        backgroundColor:
                          actionData.expected_outcome === "blocked" &&
                          "success",
                      }}
                      onClick={() => {
                        setActionData((data) => ({
                          ...data,
                          expected_outcome: "blocked",
                        }));
                      }}
                    >
                      <FaBan />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Alerted" placement="top" arrow>
                    <Button
                      color="primary"
                      variant={
                        actionData.expected_outcome === "alerted"
                          ? "contained"
                          : "outlined"
                      }
                      sx={{
                        backgroundColor:
                          actionData.expected_outcome === "alerted" &&
                          "primary",
                      }}
                      onClick={() => {
                        setActionData((data) => ({
                          ...data,
                          expected_outcome: "alerted",
                        }));
                      }}
                    >
                      <FaExclamationTriangle />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Logged" placement="top" arrow>
                    <Button
                      color="logged"
                      variant={
                        actionData.expected_outcome === "logged"
                          ? "contained"
                          : "outlined"
                      }
                      sx={{
                        backgroundColor:
                          actionData.expected_outcome === "logged" && "logged",
                      }}
                      onClick={() => {
                        setActionData((data) => ({
                          ...data,
                          expected_outcome: "logged",
                        }));
                      }}
                    >
                      <FaFileCode />
                    </Button>
                  </Tooltip>
                  <Tooltip title="No Evidence" placement="top" arrow>
                    <Button
                      color="error"
                      variant={
                        actionData.expected_outcome === "nothing"
                          ? "contained"
                          : "outlined"
                      }
                      sx={{
                        backgroundColor:
                          actionData.expected_outcome === "nothing" && "error",
                      }}
                      onClick={() => {
                        setActionData((data) => ({
                          ...data,
                          expected_outcome: "nothing",
                        }));
                      }}
                    >
                      <FaEyeSlash />
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              </ThemeProvider>
            </FormControl>
          </Stack>
        </Box>
      </Drawer>
    </div>
  );
};

export default AddActionDrawer;
